function Qliq() {
    this.currentBorrow = 0;
    this.currentPercent = 0;
    this.percent = 0.015;
    this.totalSum = 0;
    this.days = 0;
};

Qliq.prototype.changePlaceTelephone = function () {
    let w = $(window).width();

    if(w < 840){
        $('.js-mobile').css('display', 'block');
        $('.js-desktop').css('display', 'none');
    }else{
        $('.js-mobile').css('display', 'none');
        $('.js-desktop').css('display', 'inline-block');
    }

    $(window).on("orientationchange", function() {
        if (window.innerHeight < window.innerWidth) {
            $('.js-mobile').css('display', 'none');
            $('.js-desktop').css('display', 'inline-block');
        }else{
            $('.js-mobile').css('display', 'block');
            $('.js-desktop').css('display', 'none');
        }
    });
};

Qliq.prototype.beginLoan = function () {

    let w = $(window).width();

    if(w < 1100){

        $('.begin-zaim').on('click', function(event){

            event.preventDefault();

            $(this).css({
                'background': '#FFF',
                'color': '#000'
            });

            $(this).find('span').css('color', '#000');

            $(this).find('.begin-zaim__image').addClass('dark-arrow')

            let link = $(this).attr('href');
            let full_link = window.location.origin + '/';

            setTimeout(()=> {
                window.location.href = full_link + link;
            }, 500);

        });

        // $('.begin-zaim__image').on('click', function(event){

        //     //alert('maloha');

        // });
    }
};

Qliq.prototype.hoverBtnMenu = function () {

    $('.container-header__header__nav__submenu').hover(function () {

        if($(this).prev().hasClass('arrow-js-main')) {
            $(this).prev().css('color','rgba(255, 255, 255, 0.7) ');
        }else{
            $(this).prev().css('color','rgba(0, 0, 0, 0.54)');
        }
    }, function () {
        $(this).prev().attr('style', '');
    });

};


Qliq.prototype.hoverBtn = function () {

    let w = $(window).width();

    // if(w < 1100) {
    //     $('.button-link__link').on('click', function() {
    //         setTimeout(console.log('123'),3000);
    //     });
    // }


    if($('.button-link').length) {

        if(w > 1100) {
            $('.button-link__link').hover(function () {

                $(this).prev().css({
                    'transform' : 'scale(1.1)',
                    'box-shadow': '0 15px 40px 0 rgba(0, 222, 199, 0.35)'
                });

                $(this).children('img').css({
                    'transform': 'scale(1.2)'
                });

                $(this).css({
                    'font-size' : '18.7px'
                });

            }, function () {

                $(this).attr('style', '');
                $(this).children('img').attr('style', '');
                $(this).prev().attr('style', '');

                // $(this).css({
                //     'font-size' : '17px',
                //     'line-height': '40px'
                // });

                // $(this).children('img').css({
                //     'width' : '20px',
                //     'height': '12px'
                // });

                // $(this).prev().css({
                //     'transform' : 'scale(1)',
                //     'box-shadow': 'none'
                // });
            });
        } else {

            $('.button-link__link').hover(function () {

                $(this).children('img').css({
                    'transform': 'scale(0.9)'
                });

                $(this).prev().css({
                    'transform' : 'scale(0.9)',
                    'box-shadow': 'none'
                });

                $(this).css('font-size', '14.4px');

            }, function () {

                $(this).children('img').attr('style','');

                $(this).prev().attr('style','');

                 $(this).attr('style','');
            });
        }

        $('.button-link__link').mousedown( function() {
            $(this).prev().css('background','#00b3a1');
        }).mouseup( function() {
            $(this).prev().css('background','#00dec7');
        });

    }

};

Qliq.prototype.getPostfixDays = function (value) {
    let lastFigure = value % 10;

    if (value > 11 && value < 15) {
        return ' дней';
    } else {
        if (lastFigure === 1) return ' день';
        if (lastFigure > 1 && lastFigure < 5) return ' дня';
        if (lastFigure === 0 || lastFigure >= 5) return ' дней';
    }
};

Qliq.prototype.getFullPage = function () {
    $('#wrapper-block').fullpage({
        onLeave(index, nextIndex, direction) {
            if (index === 3 && nextIndex === 4) {
                $('.start-block').find('.section__arrow-bottom').addClass('active-rotate').css('transform', 'rotate(45deg)');
                if ($(window).outerWidth() > 1100) {
                    $('.container-header').css('top', -$('.footer-block').outerHeight());
                }
            }

            if (index === 4 && nextIndex === 3) {
                $('.start-block').find('.section__arrow-bottom').removeClass('active-rotate').css('transform', 'rotate(-135deg)');
                if ($(window).outerWidth() > 1100) {
                    $('.container-header').css('top', 0);
                }
            }
        },
        lazyLoading: true,
        fitToSection: false,
    });

    $(document).on('click', '.section__arrow-bottom', function () {

        if ($(this).hasClass('active-rotate')) {
            $(this).removeClass('active-rotate');
            $.fn.fullpage.moveSectionUp();
        } else {
            $.fn.fullpage.moveSectionDown();
        }
    });
};

Qliq.prototype.getChangeTab = function () {
    $('.js-tab').on('click', function (e) {
        e.preventDefault();

        let currentID = $(this).attr('data-tab');

        $('.js-tab').removeClass('active-tab');
        $(this).addClass('active-tab');
        let self = $(this);

        $('.tab-block__element').each(function () {
            let id = $(this).attr('data-tab');

            if (currentID == id) {
                if ($(this).hasClass('hidden-r')) {
                    $('.tab-block__element').addClass('hidden-l')
                    $(this).removeClass('hidden-r').removeClass('hidden-l');
                    $('.tab-menu__line').width($('.active-tab').width());
                    let w = $('.line-anchor').width();
                    $('.tab-menu__line').css('left', w + 'px');
                    $('.line-anchor').removeClass('line-anchor');
                    self.parent().addClass('line-anchor');
                }
                if ($(this).hasClass('hidden-l')) {
                    $('.tab-block__element').addClass('hidden-r');
                    $(this).removeClass('hidden-l').removeClass('hidden-r');
                    $('.tab-menu__line').width($('.active-tab').width());
                    $('.tab-menu__line').css('left', '0');
                    $('.line-anchor').removeClass('line-anchor');
                    self.parent().addClass('line-anchor');
                }
            }
        });
    });

    // $(".tab-block span").on("swipeleft", function(){
    //   alert('MALOHA');
    // });
}

Qliq.prototype.setSmoothyFade = function () {
    setTimeout(()=> {
        $('.calc-block .container').css('opacity', '1');
        $('.container-header--fading').css({
            'transition': 'opacity 2s ease-in-out, top 0.3s ease-in-out',
            'opacity': '1'
        });
        $('.calc-block--background').css('background', 'rgba(17, 7, 80, 0.6)');
    }, 1000);
}

Qliq.prototype.getVideo = function () {
    $("#bgvid").get(0).play();
}

Qliq.prototype.getRanges = function () {
    let self = this;
    let fromVar = 15;

    const countPercent = () => {
        self.currentPercent = ((self.currentBorrow * self.percent) * self.days.toFixed(0)).toFixed(0);
        $('.calc-block__content__calculator__result__percent__money .percent').text(self.currentPercent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
        $('.calc-block__content__calculator__result__cashback__money').text((+self.currentPercent + +self.currentBorrow).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
    }

    $('#rangeDays').ionRangeSlider({
        min: 7,
        max: 30,
        from: fromVar,
        step: 0.0001,
        force_edges: true,
        postfix: (function () {
            return self.getPostfixDays(fromVar);
        })(),

        prettify(num) {
            return num.toFixed(0);
        },

        onStart(data) {
            self.days = data.from;

            setTimeout(() => {
                $('.js-irs-0 .irs-min').text('7 дней');
                $('.js-irs-0 .irs-max').text('30 дней');

                //alert($('#rangeSum'));

                $('.js-irs-0 .irs-min').css('visibility', 'visible');
                $('.js-irs-0 .irs-max').css('visibility', 'visible');
            }, 0)

            setTimeout(() => {
                sliderDays.update({
                    from: 15
                });

            }, 300);
        },

        onChange(data) {
            self.days = data.from;
            fromVar = data.from.toFixed(0);

            $('.js-irs-0 .irs-single').text($('.js-irs-0 .irs-single').text().match(/\d/g).join('') + self.getPostfixDays(fromVar));

            $('.js-irs-0 .irs-min').text('7 дней');
            $('.js-irs-0 .irs-max').text('30 дней');

            countPercent();

            $('.js-irs-0 .irs-min').css('visibility', 'visible');
            $('.js-irs-0 .irs-max').css('visibility', 'visible');
        }
    });

    var sliderDays = $("#rangeDays").data("ionRangeSlider");

    $('#rangeSum').ionRangeSlider({
        min: 3000,
        max: 30000,
        from: 14000,
        step: 100,
        force_edges: true,
        postfix: " <span class='rubl-icon-b'></span>",
        onStart(data) {
            $('.calc-block__content__calculator__result__sum__money .sum').text(data.from.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            self.currentBorrow = (+$('.calc-block__content__calculator__result__sum__money .sum').text().replace(/\s/g, ''));

            countPercent();

            setTimeout(() => {
                $('.js-irs-1 .irs-min').css('visibility', 'visible');
                $('.js-irs-1 .irs-max').css('visibility', 'visible');
            }, 0);

            setTimeout(() => {
                sliderPrice.update({
                    from: 14000
                });

            }, 300);
        },
        onChange(data) {
            self.currentBorrow = data.from;

            countPercent();

            $('.calc-block__content__calculator__result__sum__money .sum').text(data.from.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            $('.js-irs-1 .irs-min').css('visibility', 'visible');
            $('.js-irs-1 .irs-max').css('visibility', 'visible');
        },
    });

    var sliderPrice = $("#rangeSum").data("ionRangeSlider");

    // Change slider, by calling it's update method


    // let rubls = $('.rubl-icon-b').get(-1);
    // $(rubls).css('height', '10px');
}

Qliq.prototype.getMobileMenu = function () {
    $('a.burger').on('click', function (event) {
        $('.container-header').removeClass('animatedHeader');


        event.preventDefault();

        if ($(this).hasClass('visible')) {
            $(this).attr('style', '');
            $(this).removeClass('visible');
            $(this).prev().fadeOut('slow');
            $(this).prev().prev().css('display', 'block');
            $('body').removeClass('stop-scrolling');
            $(this).prev().find('.container-header__header__logo--mobile').css('display', 'none');
            $('.container-header__header__nav__li').find('.arrow-js').removeClass('open').find('.arrow').removeClass('arrow-up').addClass('arrow-down')
        } else {
            $('body').addClass('stop-scrolling');
            $(this).addClass('visible');
            $(this).css('background', 'url("../images/close.svg") no-repeat');
            $(this).prev().fadeIn('slow');
            $(this).prev().prev().css('display', 'none');
            $(this).prev().find('.container-header__header__logo--mobile').css('display', 'block');
            $(this).css('background-size', 'contain');

            $('.container-header__header__nav__li .mobile-submenu').removeAttr('style');
        }
    });
};

Qliq.prototype.getSubMenu = function () {
    $('.container-header__header__nav__li').on('click', function (event) {
        if ($(window).width() < 840 && $(window).width() > 699 && $('.container-header').hasClass('container-header--portrait')) {
            let self = $(this);

            if ($(this).find('.arrow-js').hasClass('open')) {
                $(this).find('.arrow').removeClass('arrow-up').addClass('arrow-down');
                $(this).find('.mobile-submenu').slideUp('slow', function () {
                    self.find('.arrow-js').removeClass('open');
                });
            } else {
                $(this).find('.mobile-submenu').slideDown('slow');
                $(this).find('.arrow-js').addClass('open');
                $(this).find('.arrow').removeClass('arrow-down').addClass('arrow-up');
            }
        } else {
            if ($(window).width() < 700) {
                let self = $(this);

                if ($(this).find('.arrow-js').hasClass('open')) {
                    $(this).find('.arrow').removeClass('arrow-up').addClass('arrow-down');
                    $(this).find('.mobile-submenu').slideUp('slow', function () {
                        self.find('.arrow-js').removeClass('open');
                    });
                } else {
                    $(this).find('.mobile-submenu').slideDown('slow');
                    $(this).find('.arrow-js').addClass('open');
                    $(this).find('.arrow').removeClass('arrow-down').addClass('arrow-up');
                }
            }
        }
    });
};

Qliq.prototype.getDeleteAutoHeight = function () {
    if ($('.mobile-footer').length) {
        let w = $(window).width();

        if (w <= 700) {
            $('.section.mobile-footer').removeClass('fp-auto-height');
        }
    }
};

Qliq.prototype.getMobileOperatingSystem = function () {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        $('.story-one').css('display', 'none');
        $('.story-second').css('display', 'none');
    }

    if (/android/i.test(userAgent)) {
        $('.story-one').css('display', 'none');
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        $('.story-second').css('display', 'none');
    }

    return "unknown";
}

Qliq.prototype.getMobileOffset = function () {

    if (screen.height < 568 || screen.width <= 1024) {
        $.fn.fullpage.setAutoScrolling(false);
    }
}

Qliq.prototype.setHeightOfTab = function () {
    return $('.tab-block').height($('.tab-block__element[data-tab="1"]').height() + 20)
}

Qliq.prototype.getMobileOrientation = function () {
    $(window).on('orientationchange', function () {
        setTimeout(() => {
            let w = $('.js-tab[data-tab="1"]').parent().width();

            if ($('.line-anchor').children('.active-tab[data-tab="1"]').length !== 0) {
                $('.tab-menu__line').css('left', 0);
            } else if ($('.line-anchor').children('.active-tab[data-tab="2"]').length !== 0) {
                $('.tab-menu__line').css('left', w + 'px')
            }

            $('.tab-menu__line').width($('.tab-menu .active-tab').width());
        }, 500);
    })
};

Qliq.prototype.animateBubble = function () {
    document.addEventListener('mousedown', function (e) {
        if (e.target.className.indexOf('irs-slider') !== -1) {
            e.target.style.transform = 'scale(1.2)';
        }
    });

    document.addEventListener('mouseup', function (e) {
        $('.irs-slider').each((ind, val) => {
            val.style.transform = 'scale(1)';
        });
    });
};

//[123]

//Метод для десктопной и мобильной версии главной страницы
Qliq.prototype.getOtherMobile = function () {

    let helpBlock;

    //Ховер + фиолетовая плашка для десктопных устройств
    if ($(window).outerWidth() > 1100) {
        $('.container-header__header__nav').mouseleave(function (event) {
            $('.container-header__header__nav > ul').removeClass('extra-transition');
            $('.container-header').css('transition', 'top .3s ease-in-out, background-color .3s ease-in-out, height 0s ease-in-out 1s');
            $('.container-header').height('84px');
        });

        $('.container-header__header__nav__li').hover(function (e) {
            if ($(this).hasClass('fadableLi')) {

                if ($(this).parent().hasClass('extra-transition')) {
                    $('.container-header').css('transition', 'all .3s ease-in-out');
                } else {
                    $(this).parent().addClass('extra-transition');
                    $('.container-header').css('transition', 'all .3s ease-in-out, height 0s ease-in-out');
                }

                $('.container-header').addClass('animatedHeader');

                if ($(this).find('.container-header__header__nav__submenu').length > 0) {
                    let currSubMenu = $(this).find('.container-header__header__nav__submenu');

                    setTimeout(() => {
                        $('.container-header').height(currSubMenu.offset().top + $(currSubMenu).outerHeight() + 30);
                    }, 0);
                } else {
                    $('.container-header').height($(this).position().top + $(this).outerHeight() + 30);
                }
            } else {
                $('.container-header__header__nav > ul').removeClass('extra-transition');
                $('.container-header').css('transition', 'top .3s ease-in-out, background-color .3s ease-in-out, height 0s ease-in-out 1s');
                $('.container-header').height('0px');
            }
        }, function (e) {
            $('.container-header').removeClass('animatedHeader');
        })
    }

    $(window).on('touchstart', function (event) {
        if(event.target.className.indexOf('arrow-js') === -1) {
            if ($(window).outerWidth() <= 1100 && $(window).outerWidth() >= 840) {
                //alert("laloo");
                if ($('.container-header').hasClass('moving-header')) {
                    $('.container-header').css('transition', 'top .3s ease-in-out, background-color .3s ease-in-out, height .3s ease-in-out');
                } else {
                    $('.container-header').css('transition', 'top .3s ease-in-out, background-color .3s ease-in-out, height 0s ease-in-out 1s');
                }

                $('.container-header').height('84px');
                $('.arrow-js').css('color', 'rgba(255, 255, 255, 1');
                $('.container-header__header__nav > ul').removeClass('extra-transition');
                $('.container-header__header__nav__li').removeClass('active1');

                if ($('.container-header').offset().top < 4) {
                    $('.container-header').removeClass('animatedHeader');
                }

                $('.container-header__header__nav__submenu').css({
                    'opacity': '0',
                    'visibility': 'hidden'
                });

                $('.container-header__header__nav__submenu').prev().css('color', '#FFF');
            }
        }
    });

    //Фиолетовая плашка + клики для планшета
    $('.container-header__header__nav__li').click(function (event) {
        if ($(window).outerWidth() <= 1100 && $(window).outerWidth() >= 840 && $('.container-header').hasClass('container-header--landscape')) {
            if ($(this).hasClass('fadableLi')) {
                event.stopPropagation();

                $('.arrow-js').css('color', '#FFF');
                $(this).find('.arrow-js').css('color', 'rgba(255, 255, 255, 0.6)');

                if ($(this).parent().hasClass('extra-transition')) {
                    $('.container-header').css('transition', 'all .3s ease-in-out');
                } else {
                    $(this).parent().addClass('extra-transition');

                    if ($('.container-header').hasClass('moving-header')) {
                        $('.container-header').css('transition', 'all .3s ease-in-out, height .3s ease-in-out');
                    } else {
                        $('.container-header').css('transition', 'all .3s ease-in-out, height 0s ease-in-out');
                    }
                }

                $(this).find('.container-header__header__nav__submenu').css({
                    'opacity': '1',
                    'visibility': 'visible'
                })

                if ($(this).hasClass('active1')) {
                    $('.arrow-js').css('color', '#FFF');
                    $(this).find('.container-header__header__nav__submenu').css({
                        'opacity': '0',
                        'visibility': 'hidden'
                    });
                    $('.container-header').removeClass('animatedHeader');
                    $('.container-header__header__nav__li').removeClass('active1');
                    $('.container-header').height('84px');

                } else {
                    $('.container-header__header__nav__li').removeClass('active1');
                    $(this).addClass('active1');
                    $('.container-header').addClass('animatedHeader');

                    $('.container-header__header__nav__submenu').css({
                        'opacity': '0',
                        'visibility': 'hidden'
                    })

                    $(this).find('.container-header__header__nav__submenu').css({
                        'opacity': '1',
                        'visibility': 'visible'
                    })

                    if ($(this).find('.container-header__header__nav__submenu').length > 0) {
                        let currSubMenu = $(this).find('.container-header__header__nav__submenu');

                        setTimeout(() => {
                            helpBlock = $('.container-header').height(currSubMenu.position().top + currSubMenu.outerHeight() + 10);
                        }, 0);
                    } else {
                        $('.container-header').height($(this).position().top + $(this).outerHeight() + 30);
                    }
                }
            } else {
                $('.container-header__header__nav > ul').removeClass('extra-transition');
                $('.container-header').css('transition', 'top .3s ease-in-out, background-color .3s ease-in-out, height 0s ease-in-out 1s');
                $('.container-header').height('0px');
                $('.container-header__header__nav__li').removeClass('active1');

                $('.container-header__header__nav__submenu').css({
                    'opacity': '0',
                    'visibility': 'hidden'
                })
            }
        }
    });

    $(window).scroll(function () {
        if ($(window).width() < 1100 && !$('body').hasClass('stop-scrolling')) {
            if ($(this).scrollTop() > 0) {
                $('.container-header').addClass('moving-header');
                $('.container-header').css('transition', 'all .3s ease-in-out');
                $('.container-header').css('height', '84px');
                $('.container-header__header__nav__submenu').css({
                    'opacity': '0',
                    'visibility': 'hidden'
                })
                $('.arrow-js').css('color', 'rgba(255, 255, 255, 1');
                $('.container-header').addClass('animatedHeader');
            } else {
                $('.container-header').removeClass('moving-header');

                if ($(this).parent().hasClass('extra-transition')) {
                    $('.container-header').css('transition', 'all .3s ease-in-out');
                } else {
                    $(this).parent().addClass('extra-transition');
                    $('.container-header').css('transition', 'all .3s ease-in-out, height 0s ease-in-out');
                }
                //$('.container-header').css('transition', 'top .3s ease-in-out');
                $('.container-header').height('84px');
                //$('.container-header__header__nav__submenu').css('display', 'none');
                $('.container-header__header__nav__submenu').css({
                    'opacity': '0',
                    'visibility': 'hidden'
                })
                $('.container-header__header__nav__li').removeClass('active1');
                $('.container-header').removeClass('animatedHeader');
            }
        }
    });
};

// [1]
Qliq.prototype.getMobileOrientationMenu = function () {
    if ($(window).outerWidth() <= 1100 && $(window).outerWidth() >= 700) {
        $('.container-header__header__nav__li').each( (index, value) => {
            if(!$(value).hasClass('fadableLi')) {
                $(value).find('a').removeClass('middle-out');
            }
        });
    }
};

//Метод для десктопной и мобильной версии всех кроме главной страницы
Qliq.prototype.getOtherMobile_2 = function () {
    //if ($(window).outerWidth() <= 1100 && $(window).outerWidth() >= 840) {

    $(window).on('touchstart', function (event) {

        if(event.target.className.indexOf('arrow-js') === -1) {

            if ($(window).outerWidth() <= 1100 && $(window).outerWidth() >= 840) {
                $('.container-header').css('transition', 'top .3s ease-in-out');
                $('.container-header').height('auto');
                $('.arrow-js').css('color', '#000');
                $('.container-header__header__nav__li').removeClass('active1');

                $('.container-header__header__nav__submenu').css('display', 'none');
                $('.container-header__header__nav__submenu').css({
                    'opacity': '0',
                    'visibility': 'hidden'
                })

                $('.loan-block__title').css('opacity', 1);

                $('.profile-wrapper').find('h1').css('opacity', 1);

                if($('.about__title').length){
                    $('.about__title').find('h1').css('opacity', 1);
                }

                if($('.bonus__title').length){
                    $('.bonus__title').find('h1').css('opacity', 1);
                }

                if($('.conditions__title').length){
                    $('.conditions__title').find('h1').css('opacity', 1);
                }

                if($('.howTo__title').length){
                    $('.howTo__title').find('h1').css('opacity', 1);
                }

                if($('.documents__title').length){
                    $('.documents__title').find('h1').css('opacity', 1);
                }

                if($('.faq-block__title').length){
                    $('.faq-block__title').find('h1').css('opacity', 1);
                }

                if($('.container-recovery').length){
                    $('.container-recovery').find('h1').css('opacity', 1);
                }

                if($('.container-login').length){
                    $('.container-login').find('h1').css('opacity', 1);
                }
            }
        }
    });

    // $(window).click(function () {
    //     if ($(window).outerWidth() <= 1100 && $(window).outerWidth() >= 840) {
    //         if ($('.container-header').hasClass('moving-header')) {
    //             $('.container-header').css('transition', 'top .3s ease-in-out, background-color .3s ease-in-out, height .3s ease-in-out');
    //         } else {
    //             $('.container-header').css('transition', 'top .3s ease-in-out, background-color .3s ease-in-out, height 0s ease-in-out 1s');
    //         }

    //         $('.container-header').height('84px');
    //         $('.arrow-js').css('color', 'rgba(255, 255, 255, 1');
    //         $('.container-header__header__nav > ul').removeClass('extra-transition');
    //         $('.container-header__header__nav__li').removeClass('active1');

    //         if ($('.container-header').offset().top < 4) {
    //             $('.container-header').removeClass('animatedHeader');
    //         }

    //         $('.container-header__header__nav__submenu').css({
    //             'opacity': '0',
    //             'visibility': 'hidden'
    //         });

    //         $('.container-header__header__nav__submenu').prev().css('color', '#FFF');
    //     }
    // });

    $('.container-header__header__nav__li').click(function (event) {
        if ($(window).outerWidth() <= 1100 && $(window).outerWidth() >= 840 && $('.container-header').hasClass('container-header--landscape')) {
            if ($(this).hasClass('fadableLi')) {
                event.stopPropagation();

                $('.arrow-js').css('color', '#000');
                $(this).find('.arrow-js').css('color', 'rgba(0, 0, 0, 0.8)');
                $(this).find('.container-header__header__nav__submenu').css('transition', 'all .3s ease-in-out');
                $(this).find('.container-header__header__nav__submenu').css({
                    'opacity': '1',
                    'visibility': 'visible'
                })

                if ($(this).hasClass('active1')) {
                    $('.loan-block__title').css('opacity', 1);

                    $('.profile-wrapper').find('h1').css('opacity', 1);

                    if($('.about__title').length){
                        $('.about__title').find('h1').css('opacity', 1);
                    }

                    if($('.bonus__title').length){
                        $('.bonus__title').find('h1').css('opacity', 1);
                    }

                    if($('.conditions__title').length){
                        $('.conditions__title').find('h1').css('opacity', 1);
                    }

                    if($('.howTo__title').length){
                        $('.howTo__title').find('h1').css('opacity', 1);
                    }

                    if($('.documents__title').length){
                        $('.documents__title').find('h1').css('opacity', 1);
                    }

                    if($('.faq-block__title').length){
                        $('.faq-block__title').find('h1').css('opacity', 1);
                    }

                    if($('.container-recovery').length){
                        $('.container-recovery').find('h1').css('opacity', 1);
                    }

                    if($('.container-login').length){
                        $('.container-login').find('h1').css('opacity', 1);
                    }

                    $('.arrow-js').css('color', '#000');
                    $(this).find('.container-header__header__nav__submenu').css({
                        'opacity': '0',
                        'visibility': 'hidden'
                    })
                    $('.container-header__header__nav__li').removeClass('active1');
                    $('.container-header').height('auto');

                } else {

                    $('.loan-block__title').css({
                        'transition': 'opacity .3s ease-in-out',
                        'opacity': 0.1
                    });

                    $('.profile-wrapper').find('h1').css('opacity', 0.1);

                    if($('.about__title').length){
                        $('.about__title').find('h1').css('opacity', 0.1);
                    }

                    if($('.bonus__title').length){
                        $('.bonus__title').find('h1').css('opacity', 0.1);
                    }

                    if($('.conditions__title').length){
                        $('.conditions__title').find('h1').css('opacity', 0.1);
                    }

                    if($('.howTo__title').length){
                        $('.howTo__title').find('h1').css('opacity', 0.1);
                    }

                    if($('.documents__title').length){
                        $('.documents__title').find('h1').css('opacity', 0.1);
                    }

                    if($('.faq-block__title').length){
                        $('.faq-block__title').find('h1').css('opacity', 0.1);
                    }

                    if($('.container-recovery').length){
                        $('.container-recovery').find('h1').css('opacity', 0.1);
                    }

                    if($('.container-login').length){
                        $('.container-login').find('h1').css('opacity', 0.1);
                    }
                    $('.container-header__header__nav__li').removeClass('active1');
                    $(this).addClass('active1');

                    $('.container-header__header__nav__submenu').css({
                        'opacity': '0',
                        'visibility': 'hidden'
                    })

                    $(this).find('.container-header__header__nav__submenu').css({
                        'opacity': '1',
                        'visibility': 'visible'
                    })

                    if ($(this).find('.container-header__header__nav__submenu').length > 0) {
                        let currSubMenu = $(this).find('.container-header__header__nav__submenu');

                        setTimeout(() => {
                            $('.container-header').height(currSubMenu.position().top + currSubMenu.outerHeight() + 10);
                        }, 0);
                    } else {
                        $('.container-header').height($(this).position().top + $(this).outerHeight() + 30);
                    }
                }
            } else {
                $('.container-header__header__nav__li').removeClass('active1');

                $('.container-header__header__nav__submenu').css({
                    'opacity': '0',
                    'visibility': 'hidden'
                });
            }
        }
    });

    $(window).scroll(function () {
        if ($(window).width() < 1100 && !$('body').hasClass('stop-scrolling') && location.href === '/' && location.href === "/index.html") {
            if ($(this).scrollTop() > 0) {
                $('.container-header').css('transition', 'all .3s ease-in-out');
                $('.container-header').addClass('animatedHeader');
            } else {
                $('.container-header').css('transition', 'top .3s ease-in-out');
                $('.container-header').height('auto');
                $('.container-header__header__nav__submenu').css('display', 'none');
                $('.container-header__header__nav__li').removeClass('active1');
                $('.container-header').removeClass('animatedHeader');
            }
        }
    });
}

Qliq.prototype.getNewHeader = function () {

    let w = $(window).width();

    if(w > 1100) {

        $('.container-header__header__nav__li').hover(function () {
            if ($(this).hasClass('fadableLi')) {
                //$('.loan-block__title').css('opacity', 0.1);
                $('.loan-block__title').css({
                    'transition': 'opacity .3s ease-in-out',
                    'opacity': 0.1
                });

                $('.profile-wrapper').find('h1').css('opacity', 0.1);

                if($('.about__title').length){
                    $('.about__title').find('h1').css('opacity', 0.1);
                }

                if($('.bonus__title').length){
                    $('.bonus__title').find('h1').css('opacity', 0.1);
                }

                if($('.conditions__title').length){
                    $('.conditions__title').find('h1').css('opacity', 0.1);
                }

                if($('.howTo__title').length){
                    $('.howTo__title').find('h1').css('opacity', 0.1);
                }

                if($('.documents__title').length){
                    $('.documents__title').find('h1').css('opacity', 0.1);
                }

                if($('.faq-block__title').length){
                    $('.faq-block__title').find('h1').css('opacity', 0.1);
                }

                if($('.container-recovery').length){
                    $('.container-recovery').find('h1').css('opacity', 0.1);
                }

                if($('.container-login').length){
                    $('.container-login').find('h1').css('opacity', 0.1);
                }
            }
        }, function () {
            if ($(this).hasClass('fadableLi')) {
                $('.loan-block__title').css('opacity', 1);

                $('.profile-wrapper').find('h1').css('opacity', 1);


                if($('.about__title').length){
                    $('.about__title').find('h1').css('opacity', 1);
                }

                if($('.bonus__title').length){
                    $('.bonus__title').find('h1').css('opacity', 1);
                }

                if($('.conditions__title').length){
                    $('.conditions__title').find('h1').css('opacity', 1);
                }

                if($('.howTo__title').length){
                    $('.howTo__title').find('h1').css('opacity', 1);
                }

                if($('.documents__title').length){
                    $('.documents__title').find('h1').css('opacity', 1);
                }

                if($('.faq-block__title').length){
                    $('.faq-block__title').find('h1').css('opacity', 1);
                }

                if($('.container-recovery').length){
                    $('.container-recovery').find('h1').css('opacity', 1);
                }

                if($('.container-login').length){
                    $('.container-login').find('h1').css('opacity', 1);
                }
            }
        });
    }
}

//**************************ЛИЧНЫЙ КАБИНЕТ**************************************

//Личный кабинет - ПЕРЕКЛЮЧЕНИЕ БЛОКОВ
Qliq.prototype.getChangeTabLk = function () {

    $('.js-tab-lk').on('click', function (event) {

        event.preventDefault();

        let $indicator = $(this).parent();
        let $picture = $(this).prev();

        if ($indicator.hasClass('active')) {

        } else {
            //Меняем выбраный пункт меню
            //Удаляем старую иконку
            $('.js-tab-lk').each(function () {
                let $edit = $(this).parent().attr('data-class');
                let $class = 'ic--' + $edit + '-active';
                $(this).prev().removeClass($class);
            });

            //Ставим новую иконку
            let $class = 'ic--' + $indicator.attr('data-class') + '-active';
            $picture.addClass($class);

            // Меняем цвет пукнта меню
            $('.js-tab-lk').parent().removeClass('active');
            $indicator.addClass('active');

            //Меняем актуальный блок
            let currentID = $indicator.attr('data-tab');

            $('.main-block-lk').each(function () {
                let id = $(this).attr('data-id');
                if (currentID === id) {

                    //Костыль для скрытия/блока с новым займом(плюс в кружке) блока
                    // if((currentID == 1) && ($(this).hasClass('profile-container__main__no-zaim'))){
                    //     let w = $(window).width();
                    //     if (w > 700 && w < 1100) {
                    //         $('.line-for-newzaim').css('display' , 'block');
                    //         $('.newzaim').css('display' , 'block');
                    //     }
                    // }else{
                    //     $('.line-for-newzaim').css('display' , 'none');
                    //     $('.newzaim').css('display' , 'none');
                    // }
                    //Костыль для смены "№" на "Номер договора"
                    if(currentID == 2){
                        let w = $(window).width();
                        if($('.agreement').length) {
                            if (w < 700) {
                                $('.agreement').text('Номер договора');
                            }
                        }
                    }

                    $(this).fadeIn('slow');
                } else {
                    $(this).css('display', 'none');
                }

            });
        }
    });
}

//Личный кабинет - механика внутри блока ПРОФИЛЯ
Qliq.prototype.getOpenCloseProfile = function () {

    $('.js-info-more').on('click', function (event) {

        if ($(this).hasClass('open-slide')) {

            event.preventDefault();

        } else {

            event.preventDefault();

            let w = $(window).width();

            if(w > 700) {
                $('.information').css('margin-bottom', '20px');
                $('.information--small').attr('style', '');
                $('.information--contact-bottom').attr('style', '');
            }

            let $element = $(this).parent().find('.js-more-info');
            let $node = $(this).children('.js-arrow-more');
            let $change = $(this).next();

            if ($element.hasClass('hidden-block')) {
                $element.slideDown('slow', function (event) {
                    $element.removeClass('hidden-block');
                    $change.fadeIn('slow', function (event) {
                        $change.removeClass('hidden-block');
                    });
                });
                $node.removeClass('close-block');
            } else {
                $element.slideUp('slow', function (event) {
                    $element.addClass('hidden-block');
                    $node.addClass('close-block');
                    $change.fadeOut('fast', function (event) {
                        $change.addClass('hidden-block');
                    });
                });
                $node.addClass('close-block');
            }
        }
    });
}

Qliq.prototype.showPopup = function () {

    let w = $(window).width();

    $('.js-show-popup').on("click tap", function () {

        if (w > 700) {

            $('.popup.' + $(this).attr("rel")).fadeIn(500);
            $("body").append("<div id='overlay'></div>");
            $('#overlay').show().css({'filter': 'alpha(opacity=80)'});
            $("body").animate({scrollTop: 0}, '300');
            $('.container-header').css('z-index', '0');
            $('body').addClass('stop-scrolling');
            return false;

        } else {

            let self = $(this);

            setTimeout(function() {
                $('.popup.' + $(self).attr("rel")).fadeIn(500);
                $('body').addClass('stop-scrolling');
                // $("body").append("<div id='overlay'></div>");
                // $('#overlay').show().css({ 'filter': 'alpha(opacity=80)' });
                // $("body").animate({scrollTop: 0}, '300');
                $('.container-header').css('z-index', '0');
                $('.container-header__header__logo').css('display', 'none');
                return false;
            }, 300);
        }
    });
};

Qliq.prototype.closePopup = function () {

    $('.js-close-popup').on("click tap", function (event) {

        event.preventDefault();

        $('.popup').fadeOut(200);
        $('#overlay').remove('#overlay');
        $('.container-header').attr('style', '');
        $('body').removeClass('stop-scrolling');

        let w = $(window).width();

        if (w < 700) {
            $('.container-header__header__logo').css('display', 'block');
        }
        return false;
    });
};

Qliq.prototype.navigationPopup = function () {
    let currObj = this;

    let w = $(window).width();

    $('.js-next-step').on("click tap", function (event) {

        event.preventDefault();

        let self = $(this);

        if(w > 1100) {
            let stepNumber = $(this).attr('data-step');

            if (+stepNumber == 2) {
                $('.step-1').css('display', 'none');
                $('.step-2').fadeIn('slow');
            }

            if (+stepNumber == 3) {
                currObj.setTimer();
                $('.step-2').css('display', 'none');
                $('.step-3').fadeIn('slow');
            }
        }else{

            setTimeout(function() {

                let stepNumber = $(self).attr('data-step');

                if (+stepNumber == 2) {
                    $('.step-1').css('display', 'none');
                    $('.step-2').fadeIn('slow');
                }

                if (+stepNumber == 3) {
                    currObj.setTimer();
                    $('.step-2').css('display', 'none');
                    $('.step-3').fadeIn('slow');
                }

            }, 350);
        }

    });

    $('.js-prev-step').on("click tap", function (event) {

        event.preventDefault();

        clearInterval(Qliq.prototype.timerId);

        $('.step-3').css('display', 'none');
        $('.step-2').fadeIn('slow');
    });
};

Qliq.prototype.newPassword = function () {

    let currObj = this;

    let w = $(window).width();

    $('.save-new-password').on("click tap", function (event) {

        event.preventDefault();

        let self = $(this);

        if(w > 1100) {

            if (currObj.validateData($('#change-password'))) {
            }

        }else{

            setTimeout(function() {

                if (currObj.validateData($('#change-password'))) {
                }else{
                    $(self).children('img').attr('style','');

                    $(self).prev().attr('style','');

                    $(self).attr('style','');
                }

            }, 350);
        }

    });

    $('.reset-password').on("click tap", function (event) {

        event.preventDefault();

    });
};

Qliq.prototype.getSubMenuLoan = function () {
    let currObj = this;
    let w = $(window).width();

    $('.loanForm-Reg').submit(function (e) {
        e.preventDefault();

        if (currObj.validateData($(this))) {
            $.ajax({
                url: '/some-controller',
                data: {
                    surname: $('#reg-mobile').val(),
                    name: $('#reg-mail').val(),
                    middlename: $('#reg-pass').val(),
                    birth: $('#reg-verify').val(),
                }
            }).success((res) => {
            });
        }
    });

    $('#next-form').click(function () {
        $('.loanForm-Reg').submit();
    });

    $('#next-form').on('click', function (event) {
        event.preventDefault();

        let self = $(this);

        if(w > 1100) {
            if (currObj.validateData('#registration-form')) {

                $('#loan-title').text('Заявка на заём');

                let currentStep = $(self).parents('form').prev().find('.active');

                currObj.swipeElements();

                $('.loan-block__main').removeClass('hidden-main-loan');

                $(this).parents('form').next().find('div[step-index="2"]').addClass('hidden-main-loan');

                $(this).parents('form').prev().find('li').removeClass('active');

                if ($('div[data-subtab="1"]').hasClass('loan-block__main__info--active')) {
                    $('div[step-index="2"]').find('.previous-substep').addClass('previous-substep--disabled');
                }
                $(currentStep).each((ind, val) => {
                    $(val).next().addClass('active');
                });
            }
        }else{


            $(this).children('img').css({
                'width' : '16px',
                'height': '9px'
            });

            $(this).prev().css({
                'transform' : 'scale(0.9)',
                'box-shadow': 'none'
                // 'box-shadow': '0 15px 40px 0 rgba(0, 222, 199, 0.35)'
            });

            $(this).css('font-size', '14.4px');


            setTimeout(function() {

                if (currObj.validateData('#registration-form')) {

                    $('#loan-title').text('Заявка на заём');

                    let currentStep = $(self).parents('form').prev().find('.active');

                    currObj.swipeElements();

                    $('.loan-block__main').removeClass('hidden-main-loan');

                    $(self).parents('form').next().find('div[step-index="2"]').addClass('hidden-main-loan');

                    $(self).parents('form').prev().find('li').removeClass('active');

                    if ($('div[data-subtab="1"]').hasClass('loan-block__main__info--active')) {
                        $('div[step-index="2"]').find('.previous-substep').addClass('previous-substep--disabled');
                    }
                    $(currentStep).each((ind, val) => {
                        $(val).next().addClass('active');
                    });
                }

                $(self).children('img').attr('style','');

                $(self).prev().attr('style','');

                $(self).attr('style','');
            }, 350);
        }
    });

    $('.forward-substep').on('click', function (e) {

        e.preventDefault();
        //[gtc]
        //console.log(currObj.validateData('#loan-form'));

        if(w > 1100) {
            if (currObj.validateData('#loan-form')) {
                let self = $(this);


                $('#loan-title').text('Заявка на заём');

                currObj.swipeMenu.swipeForward();

                let currButtonId = +$(this).attr('data-nextsubstep');
                let currentStep = $(this).parents('.loan-block__main');
                let total_count = currentStep.find('div[data-subtab]').length;


                // console.log(currButtonId);
                // console.log(total_count);
                //Переход на следующий этап подачи заявки на заем денег
                if (currButtonId > total_count || $(this).attr('data-nextsubstep') === 'none') {
                    $('.loan-block__main').removeClass('hidden-main-loan');
                    $(currentStep).next().addClass('hidden-main-loan');

                    let tempBlock = $('.loan-block__steps__list .active');
                    let currentDesActive = $('.loan-block__steps__description .active');

                    $('.loan-block__steps__list li').removeClass('active');
                    $('.loan-block__steps__description li').removeClass('active');

                    tempBlock.next().addClass('active');
                    currentDesActive.next().addClass('active');

                }

                self.parents('.loan-block__main').find('div[data-subtab]').each(function (ind, el) {
                    let currId = +$(el).attr('data-subtab');

                    if (currId === currButtonId) {

                        if ($('div[step-index="2"]').hasClass('hidden-main-loan') && currButtonId <= total_count) {
                            let activeSwipeElement = $('.substep-swiper__item--active');

                            if ($(window).width() < 700) {
                                if (+activeSwipeElement.next().attr('data-subtab') === 2) {
                                    $('.loan-block__main__info__bottom-block').css({'padding-top': '70px', 'bottom': '20px'});
                                }

                                if (+activeSwipeElement.next().attr('data-subtab') === 3) {
                                    $('.loan-block__main__info__bottom-block').css({'padding-top': '66px', 'bottom': '5px'});
                                }

                                if (+activeSwipeElement.next().attr('data-subtab') === 4) {
                                     $('.loan-block__main__info__bottom-block').css({'padding-top': '70px', 'bottom': '21px'});
                                }
                            }

                            $('.substep-swiper__item').removeClass("loan-block__main__info--active");
                            activeSwipeElement.next().addClass("loan-block__main__info--active")

                            $('.substep-swiper__item').removeClass('substep-swiper__item--active');
                            $('.substep-swiper__item').addClass('substep-swiper__item--block');

                            activeSwipeElement.next().addClass('substep-swiper__item--active');

                            activeSwipeElement.next().css('display');

                            activeSwipeElement.css('display');

                            currObj.swipeElements();

                            setTimeout(() => {
                                $('.substep-swiper__item').removeClass('substep-swiper__item--block');
                                //$('.remove')
                            }, 800);

                            if ($('div[data-subtab="1"]').hasClass('loan-block__main__info--active')) {
                                $('div[step-index="2"]').find('.previous-substep').addClass('previous-substep--disabled');
                            } else {
                                $('div[step-index="2"]').find('.previous-substep').removeClass('previous-substep--disabled');
                            }
                        }

                        if ($('div[step-index="3"]').hasClass('hidden-main-loan') && currButtonId <= total_count) {
                            let activeSwipeElement = $('.loan-block__main__payment--active');

                            $('.loan-block__main__payment').removeClass("loan-block__main__payment--active");
                            activeSwipeElement.next().addClass("loan-block__main__payment--active");
                        }

                        if ($('div[step-index="4"]').hasClass('hidden-main-loan') && currButtonId <= total_count) {
                            let activeSwipeElement = $('.loan-block__main__verify--active');

                            $('.loan-block__main__verify').removeClass("loan-block__main__verify--active");
                            activeSwipeElement.next().addClass("loan-block__main__verify--active");

                            if ($('div[step-index="4"]').find('div[data-subtab="2"]').hasClass('loan-block__main__verify--active')) {
                                //console.log(12313213123);
                                currObj.setTimer(); //Timer of code
                            }


                            if ($(window).width() > 700 ) {
                                currentStep.find('.loan-block__main__verify__bottom-block').css('display', 'flex');
                            } else {
                                currentStep.find('.loan-block__main__verify__bottom-block').css('display', 'block');
                            }
                        }

                        self.attr('data-nextsubstep', (+self.attr('data-nextsubstep') + 1));

                        //Скрывать для верифай

                        //

                        if ($('.loan-block__main[step-index=2]').css('visibility') === 'visible') {
                            $('.loan-block__main__titles li').removeClass("active");
                            $('.loan-block__main__titles li').each(function (ind, el) {
                                if (++ind === currId) {
                                    $('.previous-substep').css('display', 'block');

                                    $(el).addClass('active');
                                }
                            });
                        }

                        // console.log(123);
                        // console.log(self.parent().parent().prev().find('.previous-substep'));
                        self.parent().parent().prev().find('.previous-substep').attr('data-prevsubstep', +self.parent().parent().prev().find('.previous-substep').attr('data-prevsubstep') + 1);
                    }
                });
            }
        }else{

            let self = $(this);

            $(this).children('img').css({
                'width' : '16px',
                'height': '9px'
            });

            $(this).prev().css({
                'transform' : 'scale(0.9)',
                'box-shadow': 'none'
            });

            $(this).css('font-size', '14.4px');

            setTimeout(function() {

                if (currObj.validateData('#loan-form')) {

                    $('#loan-title').text('Заявка на заём');

                    currObj.swipeMenu.swipeForward();

                    let currButtonId = +$(self).attr('data-nextsubstep');
                    let currentStep = $(self).parents('.loan-block__main');
                    let total_count = currentStep.find('div[data-subtab]').length;


                    // console.log(currButtonId);
                    // console.log(total_count);
                    //Переход на следующий этап подачи заявки на заем денег
                    if (currButtonId > total_count || $(self).attr('data-nextsubstep') === 'none') {

                        $(self).children('img').attr('style','');

                        $(self).prev().attr('style','');

                        $(self).attr('style','');

                        //console.log(1);
                        $('.loan-block__main').removeClass('hidden-main-loan');
                        $(currentStep).next().addClass('hidden-main-loan');

                        let tempBlock = $('.loan-block__steps__list .active');
                        let currentDesActive = $('.loan-block__steps__description .active');

                        $('.loan-block__steps__list li').removeClass('active');
                        $('.loan-block__steps__description li').removeClass('active');

                        tempBlock.next().addClass('active');
                        currentDesActive.next().addClass('active');

                    }

                    self.parents('.loan-block__main').find('div[data-subtab]').each(function (ind, el) {
                        let currId = +$(el).attr('data-subtab');

                        if (currId === currButtonId) {

                            if ($('div[step-index="2"]').hasClass('hidden-main-loan') && currButtonId <= total_count) {
                                let activeSwipeElement = $('.substep-swiper__item--active');

                                if ($(window).width() < 700) {
                                    if (+activeSwipeElement.next().attr('data-subtab') === 2) {
                                        $('.loan-block__main__info__bottom-block').css({'padding-top': '70px', 'bottom': '20px'});
                                    }

                                    if (+activeSwipeElement.next().attr('data-subtab') === 3) {
                                        $('.loan-block__main__info__bottom-block').css({'padding-top': '66px', 'bottom': '5px'});
                                    }

                                    if (+activeSwipeElement.next().attr('data-subtab') === 4) {
                                         $('.loan-block__main__info__bottom-block').css({'padding-top': '70px', 'bottom': '21px'});
                                    }
                                }

                                $('.substep-swiper__item').removeClass("loan-block__main__info--active");
                                activeSwipeElement.next().addClass("loan-block__main__info--active")

                                $('.substep-swiper__item').removeClass('substep-swiper__item--active');
                                $('.substep-swiper__item').addClass('substep-swiper__item--block');

                                activeSwipeElement.next().addClass('substep-swiper__item--active');

                                activeSwipeElement.next().css('display');

                                activeSwipeElement.css('display');

                                currObj.swipeElements();

                                setTimeout(() => {
                                    $('.substep-swiper__item').removeClass('substep-swiper__item--block');
                                    //$('.remove')
                                }, 800);

                                if ($('div[data-subtab="1"]').hasClass('loan-block__main__info--active')) {
                                    $('div[step-index="2"]').find('.previous-substep').addClass('previous-substep--disabled');
                                } else {
                                    $('div[step-index="2"]').find('.previous-substep').removeClass('previous-substep--disabled');
                                }
                            }

                            if ($('div[step-index="3"]').hasClass('hidden-main-loan') && currButtonId <= total_count) {
                                let activeSwipeElement = $('.loan-block__main__payment--active');

                                $('.loan-block__main__payment').removeClass("loan-block__main__payment--active");
                                activeSwipeElement.next().addClass("loan-block__main__payment--active");

                                //currObj.setTimer(); //Timer of code
                            }

                            if ($('div[step-index="4"]').hasClass('hidden-main-loan') && currButtonId <= total_count) {
                                let activeSwipeElement = $('.loan-block__main__verify--active');

                                $('.loan-block__main__verify').removeClass("loan-block__main__verify--active");
                                activeSwipeElement.next().addClass("loan-block__main__verify--active")

                                if ($('div[step-index="4"]').find('div[data-subtab="2"]').hasClass('loan-block__main__verify--active')) {
                                    console.log(12313213123);
                                    currObj.setTimer(); //Timer of code
                                }

                                if ($(window).width() > 700 ) {
                                    currentStep.find('.loan-block__main__verify__bottom-block').css('display', 'flex');
                                } else {
                                    currentStep.find('.loan-block__main__verify__bottom-block').css('display', 'block');
                                }
                            }

                            self.attr('data-nextsubstep', (+self.attr('data-nextsubstep') + 1));

                            //Скрывать для верифай

                            //

                            if ($('.loan-block__main[step-index=2]').css('visibility') === 'visible') {
                                $('.loan-block__main__titles li').removeClass("active");
                                $('.loan-block__main__titles li').each(function (ind, el) {
                                    if (++ind === currId) {
                                        $('.previous-substep').css('display', 'block');

                                        $(el).addClass('active');
                                    }
                                });
                            }

                            $(self).children('img').attr('style','');

                            $(self).prev().attr('style','');

                            $(self).attr('style','');

                            // console.log(123);
                            // console.log(self.parent().parent().prev().find('.previous-substep'));
                            self.parent().parent().prev().find('.previous-substep').attr('data-prevsubstep', +self.parent().parent().prev().find('.previous-substep').attr('data-prevsubstep') + 1);
                        }
                    });
                }
                $(self).children('img').attr('style','');

                $(self).prev().attr('style','');

                $(self).attr('style','');

            }, 350);
        }
    });

    $('.previous-substep').on('click', function (e) {
        e.preventDefault();
        let self = $(this);

        console.log($('.loan-block__main__info').css('transition'));

        if ($('div[step-index="2"]').hasClass('hidden-main-loan')) {
            let activeSwipeElement = $('.substep-swiper__item--active');

            if ($(window).width() < 700) {
                if (+activeSwipeElement.prev().attr('data-subtab') === 1) {
                    $('.loan-block__main__info__bottom-block').css({'padding-top': '63px', 'bottom': '10px'});
                }

                if (+activeSwipeElement.prev().attr('data-subtab') === 2) {
                    $('.loan-block__main__info__bottom-block').css({'padding-top': '70px', 'bottom': '20px'});
                }

                if (+activeSwipeElement.prev().attr('data-subtab') === 3) {
                    $('.loan-block__main__info__bottom-block').css({'padding-top': '66px', 'bottom': '5px'});
                }

                if (+activeSwipeElement.prev().attr('data-subtab') === 4) {
                     $('.loan-block__main__info__bottom-block').css({'padding-top': '70px', 'bottom': '21px'});
                }
            }

            $('.substep-swiper__item').removeClass('substep-swiper__item--active');
            $('.substep-swiper__item').addClass('substep-swiper__item--block');

            $('.substep-swiper__item').removeClass("loan-block__main__info--active");
            activeSwipeElement.prev().addClass("loan-block__main__info--active")

            activeSwipeElement.prev().addClass('substep-swiper__item--active');
            currObj.swipeElements();

            setTimeout(() => {
                $('.substep-swiper__item').removeClass('substep-swiper__item--block');
            }, 800);

            if ($('div[data-subtab="1"]').hasClass('loan-block__main__info--active')) {
                $('div[step-index="2"]').find('.previous-substep').addClass('previous-substep--disabled');
            } else {
                $('div[step-index="2"]').find('.previous-substep').removeClass('previous-substep--disabled');
            }
        }

        currObj.swipeMenu.swipeBack();

        let prevStepId = +$(this).attr('data-prevsubstep');
        let currentStep = $(this).parents('.loan-block__main');

        if (prevStepId <= 0) {
            //console.log(1);
            $('.loan-block__main').removeClass('hidden-main-loan');
            $(currentStep).prev().addClass('hidden-main-loan');

            let tempBlock = $('.loan-block__steps__list .active');
            let currentDesActive = $('.loan-block__steps__description .active');

            $('.loan-block__steps__list li').removeClass('active');
            $('.loan-block__steps__description li').removeClass('active');

            tempBlock.prev().addClass('active');
            currentDesActive.prev().addClass('active');
        }

        self.parents('.loan-block__main').find('div[data-subtab]').each(function (ind, el) {

            if (prevStepId === +$(el).attr('data-subtab')) {
                //self.parents('.loan-block__main').find('div[data-subtab]').css('display', 'none');
                self.attr('data-prevsubstep', (+self.attr('data-prevsubstep') - 1));

                if ($('div[step-index="3"]').hasClass('hidden-main-loan')) {
                    //console.log('here');
                    let activeSwipeElement = $('.loan-block__main__payment--active');

                    $('.loan-block__main__payment').removeClass("loan-block__main__payment--active");
                    activeSwipeElement.prev().addClass("loan-block__main__payment--active")
                }

                if ($('div[step-index="4"]').hasClass('hidden-main-loan')) {
                    clearInterval(Qliq.prototype.timerId);

                    let activeSwipeElement = $('.loan-block__main__verify--active');

                    $('.loan-block__main__verify').removeClass("loan-block__main__verify--active");
                    activeSwipeElement.prev().addClass("loan-block__main__verify--active");

                    currentStep.find('.loan-block__main__verify__bottom-block').css('display', 'none');
                }

                currentStep.find('.group-button-down__write .forward-substep').attr('data-nextsubstep', 2);
                currentStep.find('.group-button-down__later .previous-substep').attr('data-prevsubstep', 0);
                currentStep.find('.loan-block__main__verify__bottom-block .previous-substep').attr('data-prevsubstep', 1);
                 currentStep.find('.loan-block__main__verify__bottom-block .forward-substep').attr('data-prevsubstep', 3);

                if ($('.loan-block__main[step-index=2]').css('visibility') === 'visible') {
                    $('.loan-block__main__titles li').removeClass("active");
                    $('.loan-block__main__titles li').each(function (ind, el) {
                        if (++ind === prevStepId) {
                            $(el).addClass('active');
                        }
                    });
                }

                if (+self.parents('.loan-block__main').attr('step-index') !== 4) {
                    self.parent().next().find('.forward-substep').attr('data-nextsubstep', +self.parent().next().find('.forward-substep').attr('data-nextsubstep') - 1);
                }
            };
        });
    })
};

Qliq.prototype.validateData = function (element) {
    $.extend($.validator.messages, {
        email: "Пожалуйста, введите корректный адрес электронной почты.",
        number: "Пожалуйста, введите число.",
        snils: "Введите корректные данные"
    });

    $.validator.addMethod('simpleCheck', function (value, element) {
        return /[a-zA-Zа-яА-Я\s\-\.]{1,255}/.test(value);
    }, 'Введите корректные данные');

    $.validator.addMethod('simpleCheckNumbers', function (value, element) {
        return /[a-zA-Zа-яА-Я0-9\s\-\.]{1,255}/.test(value);
    }, 'Введите корректные данные');

    $.validator.addMethod('simpleCheckPassword', function (value, element) {
        if (/\d/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value)) {
            return true;
        }
    }, 'Пароль должен иметь цифру, заглавную и строчную букву');

    $.validator.addMethod('simpleCheckPassword_2', function(value, element) {
      if (/^[A-Za-z0-9]+$/.test(value)) {
           return true;
      }
    }, 'Пароль может содержать только буквы и цифры');

    $.validator.addMethod('simpleCheckCode', function (value, element) {
        return /[a-zA-Z0-9]{5,}/.test(value);
    }, 'Введите корректные данные');

    $.validator.addMethod('simpleCheckDigital', function(value, element) {
        return value.match(/\d/g).length < 11 ? false: true;
    }, 'Введите корректный номер телефона');

    $.validator.addMethod('simpleCheckDigital_2', function(value, element) {
       if (value === '') return true;
       return value.match(/\d/g).length < 11 ? false: true;
    }, 'Введите корректный номер телефона');

    $.validator.addMethod('simpleCheckDigitalExtra_2', function(value, element) {
        return value.match(/\d/g).length < 11 ? false: true;
    }, 'Введите корректный номер телефона');

    $.validator.addMethod('simpleCheckBlankFields', function(value, element) {
        return /[a-zA-Zа-яА-Я0-9\s\-\.]{1,255}/.test(value) || value === '' ? true: false;
    }, 'Введите корректные данные');

    let mySelector = element !== undefined ? element : $('#loan-form');

    var validator = $(mySelector).validate({
        //ignore:'',
        rules : {
            ctn_recovery: {
                required: true,
                simpleCheckDigital: true
            },
            surname_recovery: {
                required: true
            },
            ctn_login: {
                required: true,
                simpleCheckDigital: true
            },
            ctn_password: {
                required: true
            },
            accommondation_income_home_tel: {
              required: false,
            },
            income_company: {
                required: false
            },
            income_position: {
                required: false
            },
            income_experience: {
                required: false
            },
            income_work_tel: {
                required: false,
                simpleCheckDigital_2: true
            },
            gender: {
                required: true
            },
            payment: {
                required: true
            },
            verify_permission: {
                required: true
            },
            ctn: {
                required: true,
                simpleCheckDigital: true
            },
            email: 'required',
            password: {
                required: true,
                simpleCheckPassword: true,
                simpleCheckPassword_2: true,
                minlength: 8,
                maxlength: 20
            },
            password_v: {
                equalTo: '#reg-pass',
                required: true
            },
            //Личный кабинет - смена пароля
            cur_password: {
                required: true,
                minlength: 8,
                maxlength: 20
            },
            new_password: {
                required: true,
                minlength: 8,
                maxlength: 20
            },
            confirm_password: {
                equalTo: '#new_password',
                required: true,
                minlength: 8,
                maxlength: 20,
            },
            income_home_tel: {
                required: true,
                minlength: 17
            },
            surname: "required simpleCheck",
            name: "required simpleCheck",
            middlename: "required simpleCheck",
            birth: {
                required: true
            },
            birth_city: "required simpleCheck",
            passport_series: {
                required: true,
                simpleCheckNumbers: true,
                minlength: 4
            },
            passport_number: {
                required: true,
                simpleCheckNumbers: true,
                minlength: 6
            },
            department_code: {
                required: true,
                minlength: 7
            },
            passport_received: {
                required: true
            },
            passport_place: {
                required: true
            },
            passport_given: {
                required: true
            },
            snils: {
                required: true,
                minlength: 14
            },
            registration_region: {
                required: true
            },
            registration_city: {
                required: true
            },
            registration_address: {
                required: true
            },
            registration_house: "required simpleCheckNumbers",
            // registration_str: "simpleCheckBlankFields",
            // registration_unit: "simpleCheckBlankFields",
            registration_flat: "required simpleCheckNumbers",
            registration_index: {
                required: true,
                minlength: 6
            },
            accommondation_region: {
                required: true,
            },
            accommondation_city: {
                required: true
            },
            accommondation_address: {
                required: true
            },
            accommondation_house: "required simpleCheckNumbers",
            // accommondation_str: "simpleCheckBlankFields",
            // accommondation_unit: "simpleCheckBlankFields",
            accommondation_flat: "required simpleCheckNumbers",
            accommondation_index: {
                required: true,
                minlength: 6
            },
            income_salary: {
                required: true,
                number: true
            },
            verify_code: "required simpleCheckCode",
            verify_permission: {
                required: true
            },
            income_work_address: {
                required: false
            }
        },
        groups: {
            passport_number: "passport_number passport_series",
            registration_house: "registration_house registration_str registration_unit registration_flat",
            accommondation_house: "accommondation_house accommondation_str accommondation_unit accommondation_flat"
        },
        errorPlacement: function(error, element) {
            if (element.attr("name") == "passport_number" || element.attr("name") == "passport_series")  {
                error.insertAfter("#info-passport_number");
            } else if (element.attr("name") == "registration_flat" || element.attr("name") == "passport_series" || element.attr("name") == "registration_unit" || element.attr("name") == "registration_house") {
                error.insertAfter("#info-registration_flat");
            } else if (element.attr("name") == "accommondation_house" || element.attr("name") == "accommondation_str" || element.attr("name") == "accommondation_unit" || element.attr("name") == "accommondation_flat") {
                error.insertAfter("#info-accommondation_flat");
            } else if (element.attr("name") == "payment"){
                //error.insertAfter(element);
                error.insertAfter($('.radio-select--positioned:last').find('.radio-select__block').find('input'));
                //error.insertAfter();
            } else {
                error.insertAfter(element);
            }
        },
        messages: {
            ctn_recovery: {
                required: 'Поле обязательно для заполнения'
            },
            surname_recovery: {
                required: 'Поле обязательно для заполнения'
            },
            ctn_login: {
                required: 'Поле обязательно для заполнения'
            },
            ctn_password: {
                required: 'Поле обязательно для заполнения'
            },
            accommondation_income_home_tel: {
                required: 'Поле обязательно для заполнения'
            },
            //Мобильный телефон
            accommondation_region: {
                required: 'Поле обязательно для заполнения'
            },
            accommondation_city: {
                required: 'Поле обязательно для заполнения'
            },
            accommondation_address: {
                required: 'Поле обязательно для заполнения'
            },
            ctn: {
                required: "Поле обязательно для заполнения",
                minlength: "Пожалуйста введите корректные данные"
            },
            //email
            email: {
                required: "Поле обязательно для заполнения"
            },
            //Пароль
            password: {
                required: "Поле обязательно для заполнения",
                minlength: "Длина пароля от 8 до 20 символов",
                maxlength: "Длина пароля от 8 до 20 символов",
            },
            password_v: {
                required: "Поле обязательно для заполнения",
                equalTo: "Пароли не совпадают"
            },

            //Сброс пароля в ЛК
            cur_password: {
                required: "Поле обязательно для заполнения",
                minlength: "Длина пароля от 8 до 20 символов",
                maxlength: "Длина пароля от 8 до 20 символов"
            },
            new_password: {
                required: "Поле обязательно для заполнения",
                minlength: "Длина пароля от 8 до 20 символов",
                maxlength: "Длина пароля от 8 до 20 символов"
            },
            confirm_password: {
                required: "Пожалуйста введите корректные данные",
                equalTo: "Пароли не совпадают"
            },

            //ЗАЙМ - ФОРМА
            surname: {
                required: "Поле обязательно для заполнения"
            },
            name: {
                required: "Поле обязательно для заполнения"
            },
            middlename: {
                required: "Поле обязательно для заполнения"
            },
            birth: {
                required: "Поле обязательно для заполнения"
            },
            birth_city: {
                required: "Поле обязательно для заполнения"
            },
            passport_series: {
                required: "Поле обязательно для заполнения",
                minlength: "Введите корректные данные"
            },
            passport_number: {
                required: "Поле обязательно для заполнения",
                minlength: "Введите корректные данные"
            },
            department_code: {
                required: "Поле обязательно для заполнения",
                minlength: "Введите пожалуста корректные данные"
            },
            passport_received: {
                required: "Поле обязательно для заполнения"
            },
            passport_place: {
                required: "Поле обязательно для заполнения"
            },
            passport_given: {
                required: "Поле обязательно для заполнения"
            },
            snils: {
                required: "Поле обязательно для заполнения",
                minlength: "Введите пожалуста корректные данные"
            },
            registration_region: {
                required: "Поле обязательно для заполнения"
            },
            registration_city: {
                required: "Поле обязательно для заполнения"
            },
            registration_address: {
                required: "Поле обязательно для заполнения"
            },
            registration_house: {
                required: "Поле обязательно для заполнения"
            },
            registration_str: {
                required: "Поле обязательно для заполнения"
            },
            registration_unit: {
                required: "Поле обязательно для заполнения"
            },
            registration_flat: {
                required: "Поле обязательно для заполнения"
            },
            registration_index: {
                required: "Поле обязательно для заполнения",
                minlength: "Введите пожалуста корректные данные"
            },
            accommondation_house: {
                required: "Поле обязательно для заполнения"
            },
            accommondation_str: {
                required: "Поле обязательно для заполнения"
            },
            accommondation_unit: {
                required: "Поле обязательно для заполнения"
            },
            accommondation_flat: {
                required: "Поле обязательно для заполнения"
            },
            accommondation_index: {
                required: "Поле обязательно для заполнения",
                minlength: "Введите пожалуста корректные данные"
            },
            income_salary: {
                required: "Поле обязательно для заполнения"
            },
            income_company: {
                required: "Поле обязательно для заполнения"
            },
            income_position: {
                required: "Поле обязательно для заполнения"
            },
            verify_code: {
                required: "Неверный код",
            },
            gender: {
                required: "Пожалуйста выберите один из вариантов",
            },
            income_employment: {
                required: "Поле обязательно для заполнения"
            },
            income_experience: {
                required: "Поле обязательно для заполнения"
            },
            verify_permission: {
                required: "Поле обязательно для заполнения"
            },
            payment: {
                required: "Пожалуйста выберите один из вариантов",
            },
            income_work_tel: {
                required: "Поле обязательно для заполнения"
            },
            income_work_address: {
                required: "Поле обязательно для заполнения"
            },
        }
    });

    return validator.form();
}
//{1}
Qliq.prototype.maskData = function () {
    $("#recovery_mobile").masked("+7 (999) 999-99-99", {autoclear: false});
    $("#login_mobile").masked("+7 (999) 999-99-99", {autoclear: false});
    $("#reg-mobile").masked("+7 (999) 999-99-99", {autoclear: false});
    $('#info-accommondation_income_tel').masked("+7 (999) 999-99-99", {autoclear: false});
    $('#info-income_work-tel').masked("+7 (999) 999-99-99", {autoclear: false});
    //$('#reg-mobile').mask('+7(000) 000-00-00', {clearIfNotMatch: true});
    $('#info-birth').mask('00.00.0000');
    $('#info-department_code').mask('000-000');
    $('#info-passport_series').mask('0000');
    $('#info-passport_number').mask('000000');
    $('#info-passport_received').mask('00.00.0000');
    $('#info-snils').mask('000-000-000-00');
    $('#info-registration_index').mask('000000');
    // $('#info-accommondation_income_tel').mask('+7(000) 000-00-00', {clearIfNotMatch: true});
    $('#info-accommondation_index').mask('000000');
    // $('#info-income_work-tel').mask('+7(000) 000-00-00', {clearIfNotMatch: true});
    //$('#info-income_salary').mask()
};

Qliq.prototype.scrollingRules = function () {

    $('.contract-text').mCustomScrollbar({
        callbacks: {
            onTotalScroll: function () {
                if ($('.js-next-step').length) {
                    if ($('.js-next-step').hasClass('disabled')) {
                        $('.js-next-step').removeClass('disabled');
                        $('.js-next-step').prev().removeClass('button-link__layer--dis');
                        // $('.js-next-step').addClass('btn-link');
                    }
                }
            },
            alwaysTriggerOffsets: false
        }
    });
}


Qliq.prototype.changeInfoLk = function () {

    let currObj = this;

    let w = $(window).width();

    //Личные данные
    //Личные данные(Изменить-сохранить)
    $('.change-personal-info').on('click', function (event) {

        event.preventDefault();

        $(this).parent().find('.js-info-more').addClass('open-slide');

        //$('#info-passport_given').css('height', 'auto');

        $(this).fadeOut('400', function () {
            $(this).addClass('hidden-block');

            $('.ui-datepicker-trigger').css('display', 'block');

            $('#personal_submit_save').fadeIn('400', function () {
                $('#personal_submit_save').removeClass('hidden-block');
            });
        });

        $(this).parent().find('.input-disabled').removeClass('input-disabled');
        $(this).parent().find('.hasDatepicker').parents('.information__value').addClass('calendar');
        //Выбор пола
        $("input[name=gender]:radio").change(function () {
            if ($('#male').prop('checked')) {
                $('p.pol').text($('#male').val());
            }
            if ($('#female').prop('checked')) {
                $('p.pol').text($('#female').val());
            }
        });

        //Кем выдан
        $("textarea[name=passport_given]").change(function () {
            $('p.kem').text($('#info-passport_given').val());
        });

        $('#info-passport_series').removeClass('no-margin');
        $('#info-passport_series').removeClass('own-seria');

        //$("#info-passport_given").css('height', '72px');

        $('.personal-inforamtion').find('input').removeClass('input-disabled');
        $('.personal-inforamtion').find('input').removeAttr('readonly');

        $('.personal-inforamtion').find('textarea').removeClass('disabled');
        $('.personal-inforamtion').find('textarea').removeAttr('disabled');

        if ($('#personal-submit').parent().hasClass('btn-hide')) {
            $('#personal-submit').parent().removeClass('btn-hide');
        }

        $('.radio-block-p').css('display', 'none');
        $('.radio-block-form').css('display', 'block');

        $('.textarea-kem-textarea').css('display', 'block');
        $('.textarea-kem-p').css('display', 'none');

        if($('.for-delete__icon').hasClass('pers')) {
            $('.pers.for-delete__icon').removeClass("for-delete__icon--hide")
        }

        if(w < 700) {
            $('.information').css('margin-bottom', '20px');
            $('.information--small').attr('style', '');
            $('.information--contact-bottom').attr('style', '');
        }

    });


    //Личные данные(Сохранить)
    $(document).on('click', '#personal-submit', function (e) {
        e.preventDefault();

        let self = $(this);

        if(w > 1100) {

            currObj.validateData($(this).parent().parent());

            if(w < 700) {
                $('.information').attr('style', '');
                $('.information--small').attr('style', '');
                $('.information--contact-bottom').attr('style', '');
            }

            if (currObj.validateData($(this).parent().parent())) {

                $('#personal_submit_save').fadeOut('400', function () {
                    $('#personal_submit_save').addClass('hidden-block');

                    $('.ui-datepicker-trigger').css('display', 'none');
                    $('.change-personal-info').fadeIn('400', function () {
                        $('.change-personal-info').removeClass('hidden-block');
                    });
                });

                $(this).parent().parent().parent().prev().prev().prev().removeClass('open-slide');


                $('.personal-inforamtion').find('input').addClass('input-disabled');
                $(this).parent().find('.hasDatepicker').parents('.information__value').removeClass('calendar');

                $('.personal-inforamtion').find('input').attr('readonly', 'readonly');

                $('.personal-inforamtion').find('textarea').addClass('disabled');
                $('.personal-inforamtion').find('textarea').attr('disabled', 'disabled');

                if (!$('#personal-submit').parent().hasClass('btn-hide')) {
                    $('#personal-submit').parent().addClass('btn-hide');
                }

                $('.radio-block-form').css('display', 'none');
                $('.radio-block-p').css('display', 'block');

                $('.textarea-kem-p').css('display', 'block');
                $('.textarea-kem-textarea').css('display', 'none');

                $('#info-passport_series').addClass('no-margin');
                $('#info-passport_series').addClass('own-seria');

                if($('.for-delete__icon').hasClass('pers')) {
                    $('.pers.for-delete__icon').addClass("for-delete__icon--hide")
                }

                $.ajax({
                    type: 'POST',
                    url: '/personal',
                    data: {
                        surname: $(this).parent().parent().find('#info-surname').val(),
                        name: $(this).parent().parent().find('#info-name').val(),
                        middlename: $(this).parent().parent().find('#info-middlename').val(),
                        birth: $(this).parent().parent().find('#info-birth').val(),
                        birth_city: $(this).parent().parent().find('#info-birth_city').val(),
                        passport_series: $(this).parent().parent().find('#info-passport_series').val(),
                        passport_number: $(this).parent().parent().find('#info-passport_number').val(),
                        department_code: $(this).parent().parent().find('#info-department_code').val(),
                        passport_received: $(this).parent().parent().find('#info-passport_received').val(),
                        passport_place: $(this).parent().parent().find('#info-passport_place').val(),
                        passport_given: $(this).parent().parent().find('#info-passport_given').val(),
                        snils: $(this).parent().parent().find('#info-snils').val()
                    }
                }).done(function (data) {
                    //Условие запрос завершился, то...
                });
            }

        }else{

            $(this).children('img').css({
                'width' : '16px',
                'height': '9px'
            });

            $(this).prev().css({
                'transform' : 'scale(0.9)',
                'box-shadow': 'none'
            });

            $(this).css('font-size', '14.4px');

            setTimeout(function() {

                currObj.validateData($(self).parent().parent());

                if(w < 700) {
                    $('.information').attr('style', '');
                    $('.information--small').attr('style', '');
                    $('.information--contact-bottom').attr('style', '');
                }

                if (currObj.validateData($(self).parent().parent())) {

                    $('#personal_submit_save').fadeOut('400', function () {
                        $('#personal_submit_save').addClass('hidden-block');

                        $('.ui-datepicker-trigger').css('display', 'none');
                        $('.change-personal-info').fadeIn('400', function () {
                            $('.change-personal-info').removeClass('hidden-block');
                        });
                    });

                    $(self).parent().parent().parent().prev().prev().prev().removeClass('open-slide');


                    $('.personal-inforamtion').find('input').addClass('input-disabled');
                    $(self).parent().find('.hasDatepicker').parents('.information__value').removeClass('calendar');

                    $('.personal-inforamtion').find('input').attr('readonly', 'readonly');

                    $('.personal-inforamtion').find('textarea').addClass('disabled');
                    $('.personal-inforamtion').find('textarea').attr('disabled', 'disabled');

                    if (!$('#personal-submit').parent().hasClass('btn-hide')) {
                        $('#personal-submit').parent().addClass('btn-hide');
                    }

                    $('.radio-block-form').css('display', 'none');
                    $('.radio-block-p').css('display', 'block');

                    $('.textarea-kem-p').css('display', 'block');
                    $('.textarea-kem-textarea').css('display', 'none');

                    $('#info-passport_series').addClass('no-margin');
                    $('#info-passport_series').addClass('own-seria');

                    if($('.for-delete__icon').hasClass('pers')) {
                        $('.pers.for-delete__icon').addClass("for-delete__icon--hide")
                    }

                    $.ajax({
                        type: 'POST',
                        url: '/personal',
                        data: {
                            surname: $(self).parent().parent().find('#info-surname').val(),
                            name: $(self).parent().parent().find('#info-name').val(),
                            middlename: $(self).parent().parent().find('#info-middlename').val(),
                            birth: $(self).parent().parent().find('#info-birth').val(),
                            birth_city: $(self).parent().parent().find('#info-birth_city').val(),
                            passport_series: $(self).parent().parent().find('#info-passport_series').val(),
                            passport_number: $(self).parent().parent().find('#info-passport_number').val(),
                            department_code: $(self).parent().parent().find('#info-department_code').val(),
                            passport_received: $(self).parent().parent().find('#info-passport_received').val(),
                            passport_place: $(self).parent().parent().find('#info-passport_place').val(),
                            passport_given: $(self).parent().parent().find('#info-passport_given').val(),
                            snils: $(self).parent().parent().find('#info-snils').val()
                        }
                    }).done(function (data) {
                        //Условие запрос завершился, то...
                    });
                }else{

                    $(self).children('img').attr('style','');

                    $(self).prev().attr('style','');

                    $(self).attr('style','');
                }

                $(self).children('img').attr('style','');

                $(self).prev().attr('style','');

                $(self).attr('style','');
            }, 350);
        }
    });

    //Личные данные(Сохранить-изменить)
    $('#personal_submit_save').on('click', function (event) {

        event.preventDefault();

        currObj.validateData($('#personal-inforamtion'));

        if(w < 700) {
            $('.information').attr('style', '');
            $('.information--small').attr('style', '');
            $('.information--contact-bottom').attr('style', '');
        }

        if (currObj.validateData($('#personal-inforamtion'))) {

            //console.log( $('#info-passport_given'));
            //$('#info-passport_given').css('height', $('#info-passport_given').css('height', 'auto').height() - 10);
            //$('#info-passport_given').css('height', 'auto');

            $(this).fadeOut('400', function () {
                $(this).addClass('hidden-block');

                $('.ui-datepicker-trigger').css('display', 'none');

                $('.change-personal-info').fadeIn('400', function () {
                    $('.change-personal-info').removeClass('hidden-block');
                });
            });

            $(this).prev().prev().removeClass('open-slide');


            $('.personal-inforamtion').find('input').addClass('input-disabled');
            $(this).parent().find('.hasDatepicker').parents('.information__value').removeClass('calendar');

            $('.personal-inforamtion').find('input').attr('readonly', 'readonly');

            $('.personal-inforamtion').find('textarea').addClass('disabled');
            $('.personal-inforamtion').find('textarea').attr('disabled', 'disabled');

            if (!$('#personal-submit').parent().hasClass('btn-hide')) {
                $('#personal-submit').parent().addClass('btn-hide');
            }

            $('.radio-block-form').css('display', 'none');
            $('.radio-block-p').css('display', 'block');

            $('.textarea-kem-p').css('display', 'block');
            $('.textarea-kem-textarea').css('display', 'none');

            $('#info-passport_series').addClass('no-margin');
            $('#info-passport_series').addClass('own-seria');

            if($('.for-delete__icon').hasClass('pers')) {
                $('.pers.for-delete__icon').addClass("for-delete__icon--hide")
            }

            $.ajax({
                type: 'POST',
                url: '/personal',
                data: {
                    surname: $(this).parent().find('#info-surname').val(),
                    name: $(this).parent().find('#info-name').val(),
                    middlename: $(this).parent().find('#info-middlename').val(),
                    birth: $(this).parent().find('#info-birth').val(),
                    birth_city: $(this).parent().find('#info-birth_city').val(),
                    passport_series: $(this).parent().find('#info-passport_series').val(),
                    passport_number: $(this).parent().find('#info-passport_number').val(),
                    department_code: $(this).parent().find('#info-department_code').val(),
                    passport_received: $(this).parent().find('#info-passport_received').val(),
                    passport_place: $(this).parent().find('#info-passport_place').val(),
                    passport_given: $(this).parent().find('#info-passport_given').val(),
                    snils: $(this).parent().find('#info-snils').val()
                }
            }).done(function (data) {
                //Условие запрос завершился, то...
            });
        }
    });

    //Регистрация(Изменить-сохранить)
    $('.change-registration-info').on('click tap', function (event) {

        event.preventDefault();

        if(w < 700) {
            $('.information').css('margin-bottom', '20px');
            $('.information--small').attr('style', '');
            $('.information--contact-bottom').attr('style', '');
        }

        $(this).parent().find('.js-info-more').addClass('open-slide');

        //Регистрация(улица)
        $("textarea[name=registration_address]").change(function () {
            $('p.street').text($('#info-registration_address').val());
        });

        $(this).fadeOut('400', function () {
            $(this).addClass('hidden-block');

            $('#registration_submit_save').fadeIn('400', function () {
                $('#registration_submit_save').removeClass('hidden-block');
            });
        });

        $(this).parent().find('.input-disabled').removeClass('input-disabled');
        // $('#registration_submit_save').removeClass('hidden-block');
        // $(this).addClass('hidden-block');
        // $(this).css('display','none');
        // $(this).prev().addClass('open-slide');

        $('.registration-inforamtion').find('input').removeClass('input-disabled');
        $('.registration-inforamtion').find('input').removeAttr('readonly');

        $('.registration-inforamtion').find('textarea').removeClass('disabled');
        $('.registration-inforamtion').find('textarea').removeAttr('disabled');

        if ($('#registration-submit').parent().hasClass('btn-hide')) {
            $('#registration-submit').parent().removeClass('btn-hide');
        }

        $('.registration-inforamtion .together-block__item').find('span').css('vertical-align', 'top');
        $('.registration-inforamtion .together-block').css('margin-top', '20px');

        $('.textarea-street-p').css('display', 'none');
        $('.textarea-street-textarea').css('display', 'block');

        if($('.for-delete__icon').hasClass('reg')) {
            $('.reg.for-delete__icon').removeClass("for-delete__icon--hide")
        }
    });

    //Регистрация(Сохранить)
    $(document).on('click', '#registration-submit', function (e) {

        e.preventDefault();

        let self = $(this);

        if(w > 1100) {
            currObj.validateData($('#registration-inforamtion'));

            if(w < 700) {
                $('.information').attr('style', '');
                $('.information--small').attr('style', '');
                $('.information--contact-bottom').attr('style', '');
            }

            if (currObj.validateData($('#registration-inforamtion'))) {

                $('.registration-inforamtion').find('input').addClass('input-disabled');
                $('.registration-inforamtion').find('input').attr('readonly', 'readonly')

                $('.registration-inforamtion').find('textarea').addClass('disabled');
                $('.registration-inforamtion').find('textarea').attr('disabled', 'disabled');

                if (!$('#registration-submit').parent().hasClass('btn-hide')) {
                    $('#registration-submit').parent().addClass('btn-hide');
                }

                $('#registration_submit_save').fadeOut('400', function () {
                    $('#registration_submit_save').addClass('hidden-block');

                    $('.change-registration-info').fadeIn('400', function () {
                        $('.change-registration-info').removeClass('hidden-block');
                    });
                });
                // $('#registration_submit_save').addClass('hidden-block');
                // $('.change-registration-info').removeClass('hidden-block');


                $(this).parent().parent().parent().prev().prev().prev().removeClass('open-slide');

                $('.registration-inforamtion .together-block__item').find('span').attr('style', '');
                $('.registration-inforamtion .together-block').attr('style', '');

                $('.textarea-street-p').css('display', 'block');
                $('.textarea-street-textarea').css('display', 'none');

                if($('.for-delete__icon').hasClass('reg')) {
                    $('.reg.for-delete__icon').addClass("for-delete__icon--hide")
                }

                //Условие валидация успешная, то...
                $.ajax({
                    type: 'POST',
                    url: '/registration',
                    data: {
                        registration_region: $(this).parent().parent().find('#info-registration_region').val(),
                        registration_city: $(this).parent().parent().find('#info-registration_city').val(),
                        registration_address: $(this).parent().parent().find('#info-registration_address').val(),
                        registration_house: $(this).parent().parent().find('#info-registration_house').val(),
                        registration_str: $(this).parent().parent().find('#info-registration_str').val(),
                        registration_flat: $(this).parent().parent().find('#info-registration_flat').val(),
                        registration_index: $(this).parent().parent().find('#info-registration_index').val(),
                    }
                }).done(function (data) {
                    //Условие запрос завершился, то...
                });
            }
        }else{

            $(this).children('img').css({
                'width' : '16px',
                'height': '9px'
            });

            $(this).prev().css({
                'transform' : 'scale(0.9)',
                'box-shadow': 'none'
            });

            $(this).css('font-size', '14.4px');

            setTimeout(function() {

                currObj.validateData($('#registration-inforamtion'));

                if(w < 700) {
                    $('.information').attr('style', '');
                    $('.information--small').attr('style', '');
                    $('.information--contact-bottom').attr('style', '');
                }

                if (currObj.validateData($('#registration-inforamtion'))) {

                    $('.registration-inforamtion').find('input').addClass('input-disabled');
                    $('.registration-inforamtion').find('input').attr('readonly', 'readonly')

                    $('.registration-inforamtion').find('textarea').addClass('disabled');
                    $('.registration-inforamtion').find('textarea').attr('disabled', 'disabled');

                    if (!$('#registration-submit').parent().hasClass('btn-hide')) {
                        $('#registration-submit').parent().addClass('btn-hide');
                    }

                    $('#registration_submit_save').fadeOut('400', function () {
                        $('#registration_submit_save').addClass('hidden-block');

                        $('.change-registration-info').fadeIn('400', function () {
                            $('.change-registration-info').removeClass('hidden-block');
                        });
                    });
                    // $('#registration_submit_save').addClass('hidden-block');
                    // $('.change-registration-info').removeClass('hidden-block');


                    $(self).parent().parent().parent().prev().prev().prev().removeClass('open-slide');

                    $('.registration-inforamtion .together-block__item').find('span').attr('style', '');
                    $('.registration-inforamtion .together-block').attr('style', '');

                    $('.textarea-street-p').css('display', 'block');
                    $('.textarea-street-textarea').css('display', 'none');

                    if($('.for-delete__icon').hasClass('reg')) {
                        $('.reg.for-delete__icon').addClass("for-delete__icon--hide")
                    }

                    //Условие валидация успешная, то...
                    $.ajax({
                        type: 'POST',
                        url: '/registration',
                        data: {
                            registration_region: $(self).parent().parent().find('#info-registration_region').val(),
                            registration_city: $(self).parent().parent().find('#info-registration_city').val(),
                            registration_address: $(self).parent().parent().find('#info-registration_address').val(),
                            registration_house: $(self).parent().parent().find('#info-registration_house').val(),
                            registration_str: $(self).parent().parent().find('#info-registration_str').val(),
                            registration_flat: $(self).parent().parent().find('#info-registration_flat').val(),
                            registration_index: $(self).parent().parent().find('#info-registration_index').val(),
                        }
                    }).done(function (data) {
                        //Условие запрос завершился, то...
                    });
                }else{

                    $(self).children('img').attr('style','');

                    $(self).prev().attr('style','');

                    $(self).attr('style','');
                }

                $(self).children('img').attr('style','');

                $(self).prev().attr('style','');

                $(self).attr('style','');

            }, 350);
        }
    });

    //Регистрация(Сохранить-изменить)
    $('#registration_submit_save').on('click', function (event) {

        event.preventDefault();

        currObj.validateData($('#registration-inforamtion'));

        if(w < 700) {
            $('.information').attr('style', '');
            $('.information--small').attr('style', '');
            $('.information--contact-bottom').attr('style', '');
        }

        if (currObj.validateData($('#registration-inforamtion'))) {

            $('.registration-inforamtion').find('input').addClass('input-disabled');
            $('.registration-inforamtion').find('input').attr('readonly', 'readonly')

            $('.registration-inforamtion').find('textarea').addClass('disabled');
            $('.registration-inforamtion').find('textarea').attr('disabled', 'disabled');

            if (!$('#registration-submit').parent().hasClass('btn-hide')) {
                $('#registration-submit').parent().addClass('btn-hide');
            }

            $('#registration_submit_save').fadeOut('400', function () {
                $('#registration_submit_save').addClass('hidden-block');

                $('.change-registration-info').fadeIn('400', function () {
                    $('.change-registration-info').removeClass('hidden-block');
                });
            });
            $(this).prev().prev().removeClass('open-slide');

            $('.registration-inforamtion .together-block__item').find('span').attr('style', '');
            $('.registration-inforamtion .together-block').attr('style', '');

            $('.textarea-street-p').css('display', 'block');
            $('.textarea-street-textarea').css('display', 'none');

            if($('.for-delete__icon').hasClass('reg')) {
                $('.reg.for-delete__icon').addClass("for-delete__icon--hide")
            }

            $.ajax({
                type: 'POST',
                url: '/registration',
                data: {
                    registration_region: $(this).parent().find('#info-registration_region').val(),
                    registration_city: $(this).parent().find('#info-registration_city').val(),
                    registration_address: $(this).parent().find('#info-registration_address').val(),
                    registration_house: $(this).parent().find('#info-registration_house').val(),
                    registration_str: $(this).parent().find('#info-registration_str').val(),
                    registration_flat: $(this).parent().find('#info-registration_flat').val(),
                    registration_index: $(this).parent().find('#info-registration_index').val(),
                }
            }).done(function (data) {
                //Условие запрос завершился, то...
            });
        }
    });


    //Проживание(Изменить-сохранить)
    $('.change-accommodation-info').on('click', function (event) {

        event.preventDefault();

        $(this).parent().find('.js-info-more').addClass('open-slide');

        if(w < 700) {
            $('.information').css('margin-bottom', '20px');
            $('.information--small').attr('style', '');
            $('.information--contact-bottom').attr('style', '');
        }

        //Проживание(улица)
        $("textarea[name=accommondation_address]").change(function () {
            $('p.street-accom').text($('#info-accommondation_address').val());
        });

        $(this).fadeOut('400', function () {
            $(this).addClass('hidden-block');

            $('#accommodation_submit_save').fadeIn('400', function () {
                $('#accommodation_submit_save').removeClass('hidden-block');
            });
        });

        $('.accommodation-inforamtion').find('input').removeClass('input-disabled');
        $('.accommodation-inforamtion').find('input').removeAttr('readonly');

        $('.accommodation-inforamtion').find('textarea').removeClass('disabled');
        $('.accommodation-inforamtion').find('textarea').removeAttr('disabled');

        if ($('#accommodation-submit').parent().hasClass('btn-hide')) {
            $('#accommodation-submit').parent().removeClass('btn-hide');
        }

        $('.accommodation-inforamtion .together-block__item').find('span').css('vertical-align', 'top');
        $('.accommodation-inforamtion .together-block').css('margin-top', '20px');

        $('.textarea-accom-street-p').css('display', 'none');
        $('.textarea-accom-street-textarea').css('display', 'block');

        if($('.for-delete__icon').hasClass('accom')) {
            $('.accom.for-delete__icon').removeClass("for-delete__icon--hide")
        }
    });

    //Проживание(Сохранить)
    $(document).on('click', '#accommodation-submit', function (e) {

        e.preventDefault();

        let self = $(this);

        if(w > 1100) {
            currObj.validateData($('#accommodation-inforamtion'));

            if(w < 700) {
                $('.information').attr('style', '');
                $('.information--small').attr('style', '');
                $('.information--contact-bottom').attr('style', '');
            }

            if (currObj.validateData($('#accommodation-inforamtion'))) {

                $('#accommodation_submit_save').fadeOut('400', function () {
                    $('#accommodation_submit_save').addClass('hidden-block');

                    $('.change-accommodation-info').fadeIn('400', function () {
                        $('.change-accommodation-info').removeClass('hidden-block');
                    });
                });

                $(this).parent().parent().parent().prev().prev().prev().removeClass('open-slide');

                $('.accommodation-inforamtion').find('input').addClass('input-disabled');
                $('.accommodation-inforamtion').find('input').attr('readonly', 'readonly')

                $('.accommodation-inforamtion').find('textarea').addClass('disabled');
                $('.accommodation-inforamtion').find('textarea').attr('disabled', 'disabled');

                if (!$('#accommodation-submit').parent().hasClass('btn-hide')) {
                    $('#accommodation-submit').parent().addClass('btn-hide');
                }

                $('.accommodation-inforamtion .together-block__item').find('span').attr('style', '');
                $('.accommodation-inforamtion .together-block').attr('style', '');

                $('.textarea-accom-street-p').css('display', 'block');
                $('.textarea-accom-street-textarea').css('display', 'none');

                if($('.for-delete__icon').hasClass('accom')) {
                    $('.accom.for-delete__icon').addClass("for-delete__icon--hide")
                }

                //  //Условие валидация успешная, то...
                $.ajax({
                    type: 'POST',
                    url: '/accommondation',
                    data: {
                        accommondation_region: $(this).parent().parent().find('#info-accommondation_region').val(),
                        accommondation_city: $(this).parent().parent().find('#info-accommondation_city').val(),
                        accommondation_address: $(this).parent().parent().find('#info-accommondation_address').val(),
                        accommondation_house: $(this).parent().parent().find('#info-accommondation_house').val(),
                        accommondation_str: $(this).parent().parent().find('#info-accommondation_str').val(),
                        accommondation_flat: $(this).parent().parent().find('#info-accommondation_flat').val(),
                        accommondation_index: $(this).parent().parent().find('#info-accommondation_index').val(),
                    }
                }).done(function (data) {
                    //Условие запрос завершился, то...
                });
            }
        }else{

            $(this).children('img').css({
                'width' : '16px',
                'height': '9px'
            });

            $(this).prev().css({
                'transform' : 'scale(0.9)',
                'box-shadow': 'none'
            });

            $(this).css('font-size', '14.4px');

            setTimeout(function() {

                currObj.validateData($('#accommodation-inforamtion'));

                if(w < 700) {
                    $('.information').attr('style', '');
                    $('.information--small').attr('style', '');
                    $('.information--contact-bottom').attr('style', '');
                }

                if (currObj.validateData($('#accommodation-inforamtion'))) {

                    $('#accommodation_submit_save').fadeOut('400', function () {
                        $('#accommodation_submit_save').addClass('hidden-block');

                        $('.change-accommodation-info').fadeIn('400', function () {
                            $('.change-accommodation-info').removeClass('hidden-block');
                        });
                    });

                    $(self).parent().parent().parent().prev().prev().prev().removeClass('open-slide');

                    $('.accommodation-inforamtion').find('input').addClass('input-disabled');
                    $('.accommodation-inforamtion').find('input').attr('readonly', 'readonly')

                    $('.accommodation-inforamtion').find('textarea').addClass('disabled');
                    $('.accommodation-inforamtion').find('textarea').attr('disabled', 'disabled');

                    if (!$('#accommodation-submit').parent().hasClass('btn-hide')) {
                        $('#accommodation-submit').parent().addClass('btn-hide');
                    }

                    $('.accommodation-inforamtion .together-block__item').find('span').attr('style', '');
                    $('.accommodation-inforamtion .together-block').attr('style', '');

                    $('.textarea-accom-street-p').css('display', 'block');
                    $('.textarea-accom-street-textarea').css('display', 'none');

                    if($('.for-delete__icon').hasClass('accom')) {
                        $('.accom.for-delete__icon').addClass("for-delete__icon--hide")
                    }

                    // 	//Условие валидация успешная, то...
                    $.ajax({
                        type: 'POST',
                        url: '/accommondation',
                        data: {
                            accommondation_region: $(self).parent().parent().find('#info-accommondation_region').val(),
                            accommondation_city: $(self).parent().parent().find('#info-accommondation_city').val(),
                            accommondation_address: $(self).parent().parent().find('#info-accommondation_address').val(),
                            accommondation_house: $(self).parent().parent().find('#info-accommondation_house').val(),
                            accommondation_str: $(self).parent().parent().find('#info-accommondation_str').val(),
                            accommondation_flat: $(self).parent().parent().find('#info-accommondation_flat').val(),
                            accommondation_index: $(self).parent().parent().find('#info-accommondation_index').val(),
                        }
                    }).done(function (data) {
                        //Условие запрос завершился, то...
                    });
                }else{

                    $(self).children('img').attr('style','');

                    $(self).prev().attr('style','');

                    $(self).attr('style','');
                }

                $(self).children('img').attr('style','');

                $(self).prev().attr('style','');

                $(self).attr('style','');

            }, 350);
        }
    });

    //Проживание(Сохранить-изменить)
    $('#accommodation_submit_save').on('click', function (event) {

        event.preventDefault();

        currObj.validateData($('#accommodation-inforamtion'));

        if(w < 700) {
            $('.information').attr('style', '');
            $('.information--small').attr('style', '');
            $('.information--contact-bottom').attr('style', '');
        }

        if (currObj.validateData($('#accommodation-inforamtion'))) {

            $('#accommodation_submit_save').fadeOut('400', function () {
                $('#accommodation_submit_save').addClass('hidden-block');

                $('.change-accommodation-info').fadeIn('400', function () {
                    $('.change-accommodation-info').removeClass('hidden-block');
                });
            });

            $(this).prev().prev().removeClass('open-slide');

            $('.accommodation-inforamtion').find('input').addClass('input-disabled');
            $('.accommodation-inforamtion').find('input').attr('readonly', 'readonly')

            $('.accommodation-inforamtion').find('textarea').addClass('disabled');
            $('.accommodation-inforamtion').find('textarea').attr('disabled', 'disabled');

            if (!$('#accommodation-submit').parent().hasClass('btn-hide')) {
                $('#accommodation-submit').parent().addClass('btn-hide');
            }

            $('.accommodation-inforamtion .together-block__item').find('span').attr('style', '');
            $('.accommodation-inforamtion .together-block').attr('style', '');

            $('.textarea-accom-street-p').css('display', 'block');
            $('.textarea-accom-street-textarea').css('display', 'none');

            if($('.for-delete__icon').hasClass('accom')) {
                $('.accom.for-delete__icon').addClass("for-delete__icon--hide")
            }

            $.ajax({
                type: 'POST',
                url: '/accommondation',
                data: {
                    accommondation_region: $(this).parent().find('#info-accommondation_region').val(),
                    accommondation_city: $(this).parent().find('#info-accommondation_city').val(),
                    accommondation_address: $(this).parent().find('#info-accommondation_address').val(),
                    accommondation_house: $(this).parent().find('#info-accommondation_house').val(),
                    accommondation_str: $(this).parent().find('#info-accommondation_str').val(),
                    accommondation_flat: $(this).parent().find('#info-accommondation_flat').val(),
                    accommondation_index: $(this).parent().find('#info-accommondation_index').val(),
                }
            }).done(function (data) {
                //Условие запрос завершился, то...
            });
        }
    });

    //Занятость и доходы(Изменить-сохранить)
    $('.change-income-info').on('click tap', function (event) {

        event.preventDefault();

        if(w < 700) {
            $('.information').css('margin-bottom', '20px');
            $('.information--small').attr('style', '');
            $('.information--contact-bottom').attr('style', '');
        }

        $(this).parent().find('.js-info-more').addClass('open-slide');

        //Название компании
        $("textarea[name=income_company]").change(function () {
            $('p.company-p').text($('#info-income_company').val());
        });

        //Должность
        $("textarea[name=income_position]").change(function () {
            $('p.position-p').text($('#info-income_position').val());
        });

        //Фактический адрес
        $("textarea[name=income_work-address]").change(function () {
            $('p.adrress-p').text($('#info-income_work-address').val());
        });

        $(this).fadeOut('400', function () {
            $(this).addClass('hidden-block');

            $('#income_submit_save').fadeIn('400', function () {
                $('#income_submit_save').removeClass('hidden-block');
            });
        });

        $('.income-inforamtion').find('input').removeClass('input-disabled');
        $('.income-inforamtion').find('input').removeAttr('readonly');

        $('.income-inforamtion').find('textarea').removeClass('disabled');
        $('.income-inforamtion').find('textarea').removeAttr('disabled');

        if ($('#income-submit').parent().hasClass('btn-hide')) {
            $('#income-submit').parent().removeClass('btn-hide');
        }

        $('.select-block-lk').css('display', 'block');
        $('.time').css('display', 'none');
        $('.opt').css('display', 'none');

        // $('.title-company-textarea').css('display', 'block');
        $('.title-company-textarea').fadeIn('fast');
        $('.title-company-p').css('display', 'none');

        // $('.title-position-textarea').css('display', 'block');
        $('.title-position-textarea').fadeIn('fast');
        $('.title-position-p').css('display', 'none');

        // $('.fact-adrress-textarea').css('display', 'block');
        $('.fact-adrress-textarea').fadeIn('fast');
        $('.fact-adrress-p').css('display', 'none');

        if($('.for-delete__icon').hasClass('inc')) {
            $('.inc.for-delete__icon').removeClass("for-delete__icon--hide")
        }

    });

    //Занятость и доходы(Сохранить)
    $(document).on('click', '#income-submit', function (e) {

        e.preventDefault();

        let self = $(this);

        if(w > 1100) {
            currObj.validateData($('#income-inforamtion'));

            if(w < 700) {
                $('.information').attr('style', '');
                $('.information--small').attr('style', '');
                $('.information--contact-bottom').attr('style', '');
            }

            if (currObj.validateData($('#income-inforamtion'))) {

                $('#income_submit_save').fadeOut('400', function () {
                    $('#income_submit_save').addClass('hidden-block');

                    $('.change-income-info').fadeIn('400', function () {
                        $('.change-income-info').removeClass('hidden-block');
                    });
                });

                $(this).parent().parent().parent().prev().prev().prev().removeClass('open-slide');


                $('.income-inforamtion').find('input').addClass('input-disabled');
                $('.income-inforamtion').find('input').attr('readonly', 'readonly')

                $('.income-inforamtion').find('textarea').addClass('disabled');
                $('.income-inforamtion').find('textarea').attr('disabled', 'disabled');

                if (!$('#income-submit').parent().hasClass('btn-hide')) {
                    $('#income-submit').parent().addClass('btn-hide');
                }

                $('.select-block-lk').css('display', 'none');
                $('.time').css('display', 'block');
                $('.opt').css('display', 'block');

                $('p.time').text($("#info-income_employment").chosen().val());
                $('p.opt').text($("#info-income_experience").chosen().val());


                $('.title-company-textarea').css('display', 'none');
                $('.title-company-p').css('display', 'block');

                $('.title-position-textarea').css('display', 'none');
                $('.title-position-p').css('display', 'block');

                $('.fact-adrress-textarea').css('display', 'none');
                $('.fact-adrress-p').css('display', 'block');

                if($('.for-delete__icon').hasClass('inc')) {
                    $('.inc.for-delete__icon').addClass("for-delete__icon--hide")
                }

                //Условие валидация успешная, то...
                $.ajax({
                    type: 'POST',
                    url: '/income',
                    data: {
                        income_employment: $(this).parent().parent().find('#info-income_employment').val(),
                        income_company: $(this).parent().parent().find('#info-income_company').val(),
                        income_position: $(this).parent().parent().find('#info-income_position').val(),
                        income_experience: $(this).parent().parent().find('#info-income_experience').val(),
                        income_work_tel: $(this).parent().parent().find('#info-income_work-tel').val(),
                        income_salary: $(this).parent().parent().find('#info-income_salary').val(),
                        income_work_address: $(this).parent().parent().find('#info-income_work-address').val(),
                    }
                }).done(function (data) {
                    //Условие запрос завершился, то...
                });
            }
        }else{

            $(this).children('img').css({
                'width' : '16px',
                'height': '9px'
            });

            $(this).prev().css({
                'transform' : 'scale(0.9)',
                'box-shadow': 'none'
            });

            $(this).css('font-size', '14.4px');

            setTimeout(function() {

                currObj.validateData($('#income-inforamtion'));

                if(w < 700) {
                    $('.information').attr('style', '');
                    $('.information--small').attr('style', '');
                    $('.information--contact-bottom').attr('style', '');
                }

                if (currObj.validateData($('#income-inforamtion'))) {

                    $('#income_submit_save').fadeOut('400', function () {
                        $('#income_submit_save').addClass('hidden-block');

                        $('.change-income-info').fadeIn('400', function () {
                            $('.change-income-info').removeClass('hidden-block');
                        });
                    });

                    $(self).parent().parent().parent().prev().prev().prev().removeClass('open-slide');


                    $('.income-inforamtion').find('input').addClass('input-disabled');
                    $('.income-inforamtion').find('input').attr('readonly', 'readonly')

                    $('.income-inforamtion').find('textarea').addClass('disabled');
                    $('.income-inforamtion').find('textarea').attr('disabled', 'disabled');

                    if (!$('#income-submit').parent().hasClass('btn-hide')) {
                        $('#income-submit').parent().addClass('btn-hide');
                    }

                    $('.select-block-lk').css('display', 'none');
                    $('.time').css('display', 'block');
                    $('.opt').css('display', 'block');

                    $('p.time').text($("#info-income_employment").chosen().val());
                    $('p.opt').text($("#info-income_experience").chosen().val());


                    $('.title-company-textarea').css('display', 'none');
                    $('.title-company-p').css('display', 'block');

                    $('.title-position-textarea').css('display', 'none');
                    $('.title-position-p').css('display', 'block');

                    $('.fact-adrress-textarea').css('display', 'none');
                    $('.fact-adrress-p').css('display', 'block');

                    if($('.for-delete__icon').hasClass('inc')) {
                        $('.inc.for-delete__icon').addClass("for-delete__icon--hide")
                    }

                    //Условие валидация успешная, то...
                    $.ajax({
                        type: 'POST',
                        url: '/income',
                        data: {
                            income_employment: $(self).parent().parent().find('#info-income_employment').val(),
                            income_company: $(self).parent().parent().find('#info-income_company').val(),
                            income_position: $(self).parent().parent().find('#info-income_position').val(),
                            income_experience: $(self).parent().parent().find('#info-income_experience').val(),
                            income_work_tel: $(self).parent().parent().find('#info-income_work-tel').val(),
                            income_salary: $(self).parent().parent().find('#info-income_salary').val(),
                            income_work_address: $(self).parent().parent().find('#info-income_work-address').val(),
                        }
                    }).done(function (data) {
                        //Условие запрос завершился, то...
                    });
                }else{

                    $(self).children('img').attr('style','');

                    $(self).prev().attr('style','');

                    $(self).attr('style','');
                }

                $(self).children('img').attr('style','');

                $(self).prev().attr('style','');

                $(self).attr('style','');
            }, 350);
        }
    });

    //Занятость и доходы(Сохранить-изменить)
    $('#income_submit_save').on('click', function (event) {

        event.preventDefault();

        currObj.validateData($('#income-inforamtion'));

        if(w < 700) {
            $('.information').attr('style', '');
            $('.information--small').attr('style', '');
            $('.information--contact-bottom').attr('style', '');
        }

        if (currObj.validateData($('#income-inforamtion'))) {

            $('#income_submit_save').fadeOut('400', function () {
                $('#income_submit_save').addClass('hidden-block');

                $('.change-income-info').fadeIn('400', function () {
                    $('.change-income-info').removeClass('hidden-block');
                });
            });

            $(this).prev().prev().removeClass('open-slide');


            $('.income-inforamtion').find('input').addClass('input-disabled');
            $('.income-inforamtion').find('input').attr('readonly', 'readonly')

            $('.income-inforamtion').find('textarea').addClass('disabled');
            $('.income-inforamtion').find('textarea').attr('disabled', 'disabled');

            if (!$('#income-submit').parent().hasClass('btn-hide')) {
                $('#income-submit').parent().addClass('btn-hide');
            }

            $('.select-block-lk').css('display', 'none');
            $('.time').css('display', 'block');
            $('.opt').css('display', 'block');

            $('p.time').text($("#info-income_employment").chosen().val());
            $('p.opt').text($("#info-income_experience").chosen().val());

            $('.title-company-textarea').css('display', 'none');
            $('.title-company-p').css('display', 'block');

            $('.title-position-textarea').css('display', 'none');
            $('.title-position-p').css('display', 'block');

            $('.fact-adrress-textarea').css('display', 'none');
            $('.fact-adrress-p').css('display', 'block');

            if($('.for-delete__icon').hasClass('inc')) {
                $('.inc.for-delete__icon').addClass("for-delete__icon--hide")
            }

            //Условие валидация успешная, то...
            $.ajax({
                type: 'POST',
                url: '/income',
                data: {
                    income_employment: $(this).parent().find('#info-income_employment').val(),
                    income_company: $(this).parent().find('#info-income_company').val(),
                    income_position: $(this).parent().find('#info-income_position').val(),
                    income_experience: $(this).parent().find('#info-income_experience').val(),
                    income_work_tel: $(this).parent().find('#info-income_work-tel').val(),
                    income_salary: $(this).parent().find('#info-income_salary').val(),
                    income_work_address: $(this).parent().find('#info-income_work-address').val(),
                }
            }).done(function (data) {
                //Условие запрос завершился, то...
            });
        }
    });
};

Qliq.prototype.sendLoanRequest = function () {
    let currObj = this;

    $('.loanForm').submit(function (e) {
        e.preventDefault();

        if (currObj.validateData($(this))) {

        $.ajax({
            url: '/some-controller',
            data: {
                surname: $('#info-surname').val(),
                name: $('#info-name').val(),
                middlename: $('#info-middlename').val(),
                birth: $('#info-birth').val(),
                birth_city: $('#info-birth_city').val(),
                passport_series: $('#info-passport_series').val(),
                passport_number: $('#info-passport_number').val(),
                department_code: $('#info-department_code').val(),
                passport_received: $('#info-passport_received').val(),
                passport_place: $('#info-passport_place').val(),
                passport_given: $('#info-passport_given').val(),
                snils: $('#info-snils').val(),
                registration_region: $('#info-registration_region').val(),
                registration_city: $('#info-registration_city').val(),
                registration_address: $('#info-registration_address').val(),
                registration_house: $('#info-registration_house').val(),
                registration_str: $('#info-registration_str').val(),
                registration_flat: $('#info-registration_flat').val(),
                registration_index: $('#info-registration_index').val(),
                accommondation_region: $('#info-accommondation_region').val(),
                accommondation_city: $('#info-accommondation_city').val(),
                accommondation_address: $('#info-accommondation_address').val(),
                accommondation_house: $('#info-accommondation_house').val(),
                accommondation_str: $('#info-accommondation_str').val(),
                accommondation_flat: $('#info-accommondation_flat').val(),
                accommondation_index: $('#info-accommondation_index').val(),
                income_employment: $('#info-income_employment').val(),
                income_company: $('#info-income_company').val(),
                income_position: $('#info-income_position').val(),
                income_experience: $('#info-income_experience').val(),
                income_work_tel: $('#info-income_work-tel').val(),
                income_salary: $('#info-income_salary').val(),
                income_work_address: $('#info-income_work-address').val()
            }
        }).success((res) => {
        });

        $('.loan-block__main').removeClass('hidden-main-loan');
        $('.loan-block__steps__list__item').removeClass('active');
        $('.loan-block__steps__list__item:last-of-type').addClass('active');
        $('.loan-block__main[step-index="5"]').addClass('hidden-main-loan');
        }
    });

    $('#loan-submit').click(function () {
        $('.loanForm').submit();
    });
};


Qliq.prototype.getValidation = function() {
    let currObj = this;

    $('.toggle-switcher input[name=accommodation_boolean-live]').on('change', function () {
        if ($(this).is(':checked') === true) {
            $('#info-accommondation_region').val($('#info-registration_region').val());
            $('#info-accommondation_city').val($('#info-registration_city').val());
            $('#info-accommondation_address').val($('#info-registration_address').val());
            $('#info-accommondation_house').val($('#info-registration_house').val());
            $('#info-accommondation_str').val($('#info-registration_str').val());
            $('#info-accommondation_unit').val($('#info-registration_unit').val());
            $('#info-accommondation_flat').val($('#info-registration_flat').val());
            $('#info-accommondation_index').val($('#info-registration_index').val());
            $('#info-accommondation_region').prop("readonly", true);
            $('#info-accommondation_region').prop("readonly", true);
            $('#info-accommondation_city').prop("readonly", true);
            $('#info-accommondation_address').prop("readonly", true);
            $('#info-accommondation_house').prop("readonly", true);
            $('#info-accommondation_str').prop("readonly", true);
            $('#info-accommondation_unit').prop("readonly", true);
            $('#info-accommondation_flat').prop("readonly", true);
            $('#info-accommondation_index').prop("readonly", true);

            $('#info-accommondation_region').addClass('gray');
            $('#info-accommondation_region').addClass('gray');
            $('#info-accommondation_city').addClass('gray');
            $('#info-accommondation_address').addClass('gray');
            $('#info-accommondation_house').addClass('gray');
            $('#info-accommondation_str').addClass('gray');
            $('#info-accommondation_unit').addClass('gray');
            $('#info-accommondation_flat').addClass('gray');
            $('#info-accommondation_index').addClass('gray');
        } else {
            $('#info-accommondation_region').val('');
            $('#info-accommondation_city').val('');
            $('#info-accommondation_address').val('');
            $('#info-accommondation_house').val('');
            $('#info-accommondation_str').val('');
            $('#info-accommondation_unit').val('');
            $('#info-accommondation_flat').val('');
            $('#info-accommondation_index').val('');
            $('#info-accommondation_region').prop("readonly", false);
            $('#info-accommondation_region').prop("readonly", false);
            $('#info-accommondation_city').prop("readonly", false);
            $('#info-accommondation_address').prop("readonly", false);
            $('#info-accommondation_house').prop("readonly", false);
            $('#info-accommondation_str').prop("readonly", false);
            $('#info-accommondation_unit').prop("readonly", false);
            $('#info-accommondation_flat').prop("readonly", false);
            $('#info-accommondation_index').prop("readonly", false);

            $('#info-accommondation_region').removeClass('gray');
            $('#info-accommondation_region').removeClass('gray');
            $('#info-accommondation_city').removeClass('gray');
            $('#info-accommondation_address').removeClass('gray');
            $('#info-accommondation_house').removeClass('gray');
            $('#info-accommondation_str').removeClass('gray');
            $('#info-accommondation_unit').removeClass('gray');
            $('#info-accommondation_flat').removeClass('gray');
            $('#info-accommondation_index').removeClass('gray');
        }

        currObj.validateData($('.loanForm'));
    });

    $('#info-accommondation_income_tel').prop('disabled', true);

    $('.toggle-switcher input[name=accommondation_boolean-phone]').on('change', function () {
        if ($(this).is(':checked') === true) {
            $('#info-accommondation_income_tel').prop('disabled', false);
            $('#info-accommondation_income_tel').css(
                {
                    '-webkit-user-select': 'auto',
                    '-moz-user-select': 'auto',
                    '-ms-user-select': 'auto',
                    'user-select': 'auto'

                }
            );
        } else {
            $('#info-accommondation_income_tel').prop('disabled', true);
            $('#info-accommondation_income_tel').val('');
            $('#info-accommondation_income_tel').css(
                {
                    '-webkit-user-select': 'none',
                    '-moz-user-select': 'none',
                    '-ms-user-select': 'none',
                    'user-select': 'none'

                }
            );
        }
    });

    //[123]

    $('#info-income_employment').on('change', function () {
        switch ($(this).val()) {
            case 'Безработный':
                $('#info-income_company').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_position').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_experience').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_work-tel').removeClass('error').rules('add', {
                    required: false,
                    simpleCheckDigitalExtra_2: false
                });
                $('#info-income_work-address').removeClass('error').rules('add', {
                    required: false
                });
                break;
            case 'Работаю на ставку':
                $('#info-income_company').rules('add', {
                    required: true
                });
                $('#info-income_position').rules('add', {
                    required: true
                });
                $('#info-income_experience').rules('add', {
                    required: true
                });
                $('#info-income_work-tel').rules('add', {
                    required: true,
                    simpleCheckDigitalExtra_2: true
                });
                $('#info-income_work-address').rules('add', {
                    required: true
                });
                break;
            case 'Работаю на себя':
                $('#info-income_company').rules('add', {
                    required: true
                });
                $('#info-income_position').rules('add', {
                    required: true
                });
                $('#info-income_experience').rules('add', {
                    required: true
                });
                $('#info-income_work-tel').rules('add', {
                    required: true,
                    simpleCheckDigitalExtra_2: true
                });
                $('#info-income_work-address').removeClass('error').rules('add', {
                    required: false
                });
                break;
            case 'Студент':
                $('#info-income_company').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_position').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_experience').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_work-tel').removeClass('error').rules('add', {
                    required: false,
                    simpleCheckDigitalExtra_2: false
                });
                $('#info-income_work-address').removeClass('error').rules('add', {
                    required: false
                });
                break;
            case 'Пенсионер':
                $('#info-income_company').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_position').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_experience').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_work-tel').removeClass('error').rules('add', {
                    required: false,
                    simpleCheckDigitalExtra_2: false
                });
                $('#info-income_work-address').removeClass('error').rules('add', {
                    required: false
                });
                break;
            case 'Пенсионер (работаю на ставку)':
                $('#info-income_company').rules('add', {
                    required: true
                });
                $('#info-income_position').rules('add', {
                    required: true
                });
                $('#info-income_experience').rules('add', {
                    required: true
                });
                $('#info-income_work-tel').rules('add', {
                    required: true,
                    simpleCheckDigitalExtra_2: true
                });
                $('#info-income_work-address').rules('add', {
                    required: true
                });
                break;
            case 'Пенсионер (работаю на себя)':
                $('#info-income_company').rules('add', {
                    required: true
                });
                $('#info-income_position').rules('add', {
                    required: true
                });
                $('#info-income_experience').rules('add', {
                    required: true
                });
                $('#info-income_work-tel').rules('add', {
                    required: true,
                    simpleCheckDigitalExtra_2: true
                });
                $('#info-income_work-address').rules('add', {
                    required: false
                });
                break;
            case 'Декретный отпуск':
                $('#info-income_company').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_position').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_experience').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_work-tel').removeClass('error').rules('add', {
                    required: false,
                    simpleCheckDigitalExtra_2: false
                });
                $('#info-income_work-address').removeClass('error').rules('add', {
                    required: false
                });
                break;
            case 'Другое':
                $('#info-income_company').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_position').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_experience').removeClass('error').rules('add', {
                    required: false
                });
                $('#info-income_work-tel').removeClass('error').rules('add', {
                    required: false,
                    simpleCheckDigitalExtra_2: false
                });
                $('#info-income_work-address').removeClass('error').rules('add', {
                    required: false
                });
                break;
            default:
        }

        currObj.validateData($('.loanForm'));
    })
};

Qliq.prototype.setMargin = function() {
  //if (window.pathname.match())
  let currentPage = location.pathname;

  if (currentPage.match(/how-to-get/) !== null) {
    if (currentPage.match(/how-to-get/).length > 0 && $(window).width() > 1100) {
      $('.external-wrapper-block').css('margin-bottom', '-294px');
    }

    if (currentPage.match(/how-to-get/).length > 0 && $(window).width() < 699) {
      $('.external-wrapper-block').css('margin-bottom', '-100px');
    }
  }
  //
  if (currentPage.match(/how-to-back/) !== null) {
    if (currentPage.match(/how-to-back/).length > 0 && $(window).width() > 1100) {
      $('.external-wrapper-block').css('margin-bottom', '-294px');
    }

    if (currentPage.match(/how-to-back/).length > 0 && $(window).width() < 700) {
      $('.external-wrapper-block').css('margin-bottom', '-100px');
    }
  }

  if (currentPage.match(/about/) !== null) {
    if (currentPage.match(/about/).length > 0 && $(window).width() > 1100) {
      $('.external-wrapper-block').css('margin-bottom', '-294px');
    }

    if (currentPage.match(/about/).length > 0 && $(window).width() < 700) {
      $('.external-wrapper-block').css('margin-bottom', '-100px');
    }
  }

  if (currentPage.match(/faq/) !== null) {
    if (currentPage.match(/faq/).length > 0 && $(window).width() > 1100) {
      $('.external-wrapper-block').css('margin-bottom', '-294px');
    }

    if (currentPage.match(/faq/).length > 0 && $(window).width() < 700) {
      $('.external-wrapper-block').css('margin-bottom', '-100px');
    }
  }

  if (currentPage.match(/contacts/) !== null) {
    if (currentPage.match(/contacts/).length > 0 && $(window).width() > 1100) {
      $('.external-wrapper-block').css('margin-bottom', '-294px');
    }

    if (currentPage.match(/contacts/).length > 0 && $(window).width() < 700) {
      $('.external-wrapper-block').css('margin-bottom', '-100px');
    }
  }

  if (currentPage.match(/documents/) !== null) {
    if (currentPage.match(/documents/).length > 0 && $(window).width() > 1100) {
      $('.external-wrapper-block').css('margin-bottom', '-312px');
    }

    if (currentPage.match(/documents/).length > 0 && $(window).width() < 700) {
      $('.external-wrapper-block').css('margin-bottom', '-100px');
    }

     if (currentPage.match(/documents/).length > 0 && $(window).width() > 699 && $(window).width() < 840) {
       $('.external-wrapper-block').css('margin-bottom', '-440px');
     }
  }

  if (currentPage.match(/conditions/) !== null) {
    if (currentPage.match(/conditions/).length > 0 && $(window).width() > 1100) {
      $('.external-wrapper-block').css('margin-bottom', '-294px');
    }

    if (currentPage.match(/conditions/).length > 0 && $(window).width() < 700) {
      $('.external-wrapper-block').css('margin-bottom', '-100px');
    }
  }

  if (currentPage.match(/bonus/) !== null) {
    if (currentPage.match(/bonus/).length > 0 && $(window).width() > 1100) {
      $('.external-wrapper-block').css('margin-bottom', '-294px');
    }

    if (currentPage.match(/bonus/).length > 0 && $(window).width() < 700) {
      $('.external-wrapper-block').css('margin-bottom', '-100px');
    }
  }
}

Qliq.prototype.howToTabs = function () {
    var tab = $('.howTo__tab');

    tab.click(function () {

        var clickedTab = $(this);
        var content = $(this).children('.howTo__tab-content');

        if (clickedTab.hasClass('active')) {

            // setTimeout(function() {
            // }, 200);
            content.animate({'max-height': '0'}, 300, 'linear', function () {
                clickedTab.toggleClass('active');
                content.css('padding-bottom', '0');
            });

            // setTimeout(function() {
            // 	clickedTab.toggleClass('active');
            // }, 300);

        } else {
            clickedTab.toggleClass('active');
            content.css('padding-bottom', '18px');
            content.animate({'max-height': '1000px'}, 300, 'linear');
        }
        ;
    });

    $('.howTo__tab:first-child').click();
};

Qliq.prototype.swipeMenu = {
    swipeBack() {
        if ($(window).width() < 700) {
            setTimeout(() => {
                if ($('.loan-block__main__titles').parent().css('display') !== 'none') {
                    let currActive,
                        totalMargin = 0;

                    $('.loan-block__main__titles ul li').each((ind, el) => {
                        if ($(el).hasClass('active')) {
                            currActive = $(el);

                            if (+currActive.attr('data-tab') === 4 || +currActive.attr('data-tab') === 3) {
                                $('.loan-block__main__titles').css({
                                    "margin-left": '-230px',
                                });
                            } else if (+currActive.attr('data-tab') === 2) {
                                $('.loan-block__main__titles').css({
                                    "margin-left": '-167px',
                                });
                            } else {
                                $('.loan-block__main__titles').css({
                                    "margin-left": '0px',
                                });
                            }

                            $('body,html').animate({scrollTop: 100}, 0);
                        }
                    })
                }
            }, 0);
        }
    },
    swipeForward() {
        if ($(window).width() < 700) {
            setTimeout(() => {
                if ($('.loan-block__main__titles').parent().css('display') !== 'none') {
                    let currActive,
                        totalMargin = 0;

                    $('.loan-block__main__titles ul li').each((ind, el) => {
                        if ($(el).hasClass('active')) {
                            currActive = $(el);

                            if (+currActive.attr('data-tab') === 2) {
                                $('.loan-block__main__titles').css({
                                 "margin-left": -(+currActive.prev().outerWidth() + parseInt(currActive.css('margin-right')))
                                });
                            } else {
                                $('.loan-block__main__titles').css({
                                    "margin-left": '-230px',

                                });
                            }

                            $('body,html').animate({scrollTop: 100}, 0);
                        }
                    })
                }
            }, 0);
        }
    }
};

Qliq.prototype.tieCard = function () {
    $('.radio-select__block').on('click', function () {
        $('.radio-select__tie').removeClass('active-tie');

        $(this).parent().find('.radio-select__tie').addClass('active-tie');

        $('.radio-select__block').removeClass('active');

        $(this).addClass('active')
    });
};

Qliq.prototype.swipeElements = function () {
    if ($(window).width() < 699) {

        let activeSwiperItem;
        let previousItems = [];
        let nextItems = [];
        let activeSwiperItemid = 0;

        $('.substep-swiper__item').each((ind, val) => {

            if ($(val).hasClass('substep-swiper__item--active')) {
                activeSwiperItem = $(val);
                activeSwiperItemid = ind;

                setTimeout(() => {
                   if (+$('.substep-swiper').find('.loan-block__main__info--active').attr('data-subtab') === 1) {
                        $('.substep-swiper').height(1176);
                   } else {
                        $('.substep-swiper').height($(val).outerHeight() + 176);
                   }
                }, 0);
            }
        });

        $('.substep-swiper__item').each((ind, val) => {
            if (ind < activeSwiperItemid) {
                previousItems.push($(val));
            }

            if (ind > activeSwiperItemid) {
                nextItems.push($(val));
            }
        });

        if ($(nextItems).length !== 0) {
            $(nextItems).each((ind, val) => {
                $(val).css('left', (120 * (++ind)) + '%');
            });
        }

        if ($(previousItems).length !== 0) {
            $(previousItems).each((ind, val) => {
                $(val).css('left', (-120 * (++ind)) + '%');
            });
        }

        activeSwiperItem.css('left', 0);
    }
};

Qliq.prototype.datapicker_init = function () {

    $.datepicker.setDefaults($.datepicker.regional["ru"]);
    let width_before = 0;

    function getOffsetLeft(elem) {
        var offsetLeft = 0;
        do {
            if (!isNaN(elem.offsetLeft)) {
                offsetLeft += elem.offsetLeft;
            }
        } while (elem = elem.offsetParent);
        return offsetLeft;
    }

    let d = new Date().getFullYear();

    $('#info-passport_received, .datepicker_input').datepicker({
        changeMonth: true,
        monthNamesShort: [ "Январь", "Февраль", "Март", "Апрель",
                   "Май", "Июнь", "Июль", "Август", "Сентябрь",
                   "Октябрь", "Ноябрь", "Декабрь"],
        changeYear: true,
        buttonImage: '../images/loan/ic-calendar.svg',
        buttonImageOnly: true,
        showOn: "both",
        showOtherMonths: true,
        selectOtherMonths: true,
        createButton: false,
        yearRange: '1947:' + d, // текущий год
        maxDate: new Date(),
        beforeShow: function (input, inst) {
            $('.ui-datepicker').css('border', 'solid 1px #00dec7');
            $('.ui-datepicker').css('border-top', 'none');

            width_before = $(input).outerWidth();
            $(inst.dpDiv[0]).css({'opacity': '1'});

            setTimeout(function () {
                if (width_before > 300) {
                    inst.dpDiv.addClass('for_loan');
                }

                if (width_before < 300) {
                    inst.dpDiv.addClass('for_profile');
                }
                else {
                    inst.dpDiv.removeClass('for_profile');
                }

                inst.dpDiv.css({
                    top: $(input).offset().top + $(input).outerHeight() - 2,
                    left: $(input).offset().left
                });

                $(document).delegate('.ui-datepicker-month', 'click', function() {
                    inst.dpDiv.outerWidth($(input)[0].getBoundingClientRect().width);
                });

                $(document).delegate('.ui-datepicker-year', 'click', function() {
                    inst.dpDiv.outerWidth($(input)[0].getBoundingClientRect().width);
                });

                inst.dpDiv.outerWidth($(input)[0].getBoundingClientRect().width);
            }, 1);

            setTimeout(function () {
                if($(input).hasClass('input-disabled')) {
                    inst.dpDiv.hide();
                }
            }, 1);
        },
        onSelect: function(dateText) {
            $('.ui-datepicker').css('border', 'solid 1px rgba(0, 0, 0, 0.4)');
            $('.ui-datepicker').css('border-top', 'none');
            $('#info-passport_received').removeClass('error');
            $('#info-passport_received').parent().find('label').css('display', 'none');
        },
        onChangeMonthYear: function(year, month, widget) {
            setTimeout(() => {
                $('#ui-datepicker-div').outerWidth($('#info-passport_received')[0].getBoundingClientRect().width);
            }, 0);
        }
    })

    $('.ui-corner-all').on('click', function () {
        let that = $(this);
        that.width(width_before - 1.5);
    });
    // $('.tr').dpSetPosition($.dpConst.POS_TOP, $.dpConst.POS_RIGHT);
};

Qliq.prototype.datapicker_init1 = function () {

    $.datepicker.setDefaults($.datepicker.regional["ru"]);
    let width_before = 0;

    function getOffsetLeft(elem) {
        var offsetLeft = 0;
        do {
            if (!isNaN(elem.offsetLeft)) {
                offsetLeft += elem.offsetLeft;
            }
        } while (elem = elem.offsetParent);
        return offsetLeft;
    }

    function getData() {
        let currDate = new Date();

        return new Date(currDate.setFullYear(currDate.getFullYear() - 18));
    }

    let data_check = getData();
    let year = data_check.getFullYear();

    $('#info-birth').datepicker({
        changeMonth: true,
        monthNamesShort: ["Январь", "Февраль", "Март", "Апрель",
                   "Май", "Июнь", "Июль", "Август", "Сентябрь",
                   "Октябрь", "Ноябрь", "Декабрь"],
        changeYear: true,
        buttonImage: '../images/loan/ic-calendar.svg',
        buttonImageOnly: true,
        showOn: "both",
        showOtherMonths: true,
        selectOtherMonths: true,
        createButton: false,
        yearRange: `1947:'${year}`, // текущий год
        maxDate: getData(),
        beforeShow: function (input, inst) {
            //console.log(1);
            $('.ui-datepicker').css('border', 'solid 1px #00dec7');
            $('.ui-datepicker').css('border-top', 'none');

            width_before = $(input).outerWidth();
            $(inst.dpDiv[0]).css({'opacity': '1'});

            setTimeout(function () {
                if (width_before > 300) {
                    inst.dpDiv.addClass('for_loan');
                }

                if (width_before < 300) {
                    inst.dpDiv.addClass('for_profile');
                }
                else {
                    inst.dpDiv.removeClass('for_profile');
                }
                inst.dpDiv.css({
                    top: $(input).offset().top + $(input).outerHeight() - 2,
                    left: $(input).offset().left
                });

                $(document).delegate('.ui-datepicker-month', 'click', function() {
                    inst.dpDiv.outerWidth($(input)[0].getBoundingClientRect().width);
                });

                $(document).delegate('.ui-datepicker-year', 'click', function() {
                    inst.dpDiv.outerWidth($(input)[0].getBoundingClientRect().width);
                });

                inst.dpDiv.outerWidth($(input)[0].getBoundingClientRect().width);
            }, 1);

            setTimeout(function () {
                if($(input).hasClass('input-disabled')) {
                    inst.dpDiv.hide();
                }
            });
        },
        onSelect: function(dateText) {
            $('.ui-datepicker').css('border', 'solid 1px rgba(0, 0, 0, 0.4)');
            $('.ui-datepicker').css('border-top', 'none');
            $('#info-birth').removeClass('error');
            $('#info-birth').parent().find('label').css('display', 'none');
        },
        onChangeMonthYear: function(year, month, widget) {
            setTimeout(() => {
                $('#ui-datepicker-div').outerWidth($('#info-birth')[0].getBoundingClientRect().width);
            }, 0);
        }
    });
    $('.ui-corner-all').on('click', function () {
        let that = $(this);
        that.width(width_before - 1.5);
    });
};

Qliq.prototype.changeColorSwitcher = function () {

    $('.toggle-switcher input').on('change', function () {

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).parent().next('span').removeClass('yes').addClass('no');
            $(this).parent().prev('span').removeClass('no');
        } else {
            $(this).addClass('active');
            $(this).parent().next('span').removeClass('no').addClass('yes');
            $(this).parent().prev('span').addClass('no');
        }
    });
};

Qliq.prototype.deleteErrorLabel = function () {
    $('input, textarea').focus(function () {
        $(this).parent().find('label.error').css('display', 'none');
    })
};

Qliq.prototype.changeAgreement = function () {

    if($('.agreement').length) {
        //console.log('yes');

        let w = $(window).width();

        if (w < 700) {
            $('.argeement').text('Номер договора');
            //console.log('yes');
        }
    }
};

Qliq.prototype.map_init = function () {
    ymaps.ready(init);
    let myMap;

    function init(){
        myMap = new ymaps.Map("map_yandex", {
            center: [55.76, 37.64],
            zoom: 3
        });
        myMap.behaviors.disable('scrollZoom');
        $('.map_adress_list-item').each(function () {
            let lat = $(this).data('lat');
            let lon = $(this).data('lon');
            let myPlacemark = new ymaps.Placemark([lat, lon], {
                balloonContent: $(this).data('text')
            });
            myMap.geoObjects.add(myPlacemark);
        });
    }
};

Qliq.prototype.resizeTextarea = function() {
    function observe(element, event, handler) {
        element.addEventListener(event, handler, false);
    };

    return function init() {
        var text = document.getElementById('info-passport_given');

        function resize () {
            text.style.height = 'auto';
            text.style.height = text.scrollHeight + 'px';
        }

        function delayedResize () {
            window.setTimeout(resize, 0);
        }

        observe(text, 'change',  resize);
        observe(text, 'cut',     delayedResize);
        observe(text, 'paste',   delayedResize);
        observe(text, 'drop',    delayedResize);
        observe(text, 'keydown', delayedResize);

        $('.change-personal-info').on('click', delayedResize);
        $('#personal_submit_save').on('click', delayedResize);
        $('#personal-submit').on('click', delayedResize);

        text.focus();
        text.select();
    };
};

Qliq.prototype.resizeTextarea_2 = function() {
    function observe(element, event, handler) {
        element.addEventListener(event, handler, false);
    };

    return function init() {
        var text = document.getElementById('info-registration_address');

        function resize () {
            text.style.height = 'auto';
            text.style.height = text.scrollHeight + 'px';
        }

        function delayedResize () {
            window.setTimeout(resize, 0);
        }

        observe(text, 'change',  resize);
        observe(text, 'cut',     delayedResize);
        observe(text, 'paste',   delayedResize);
        observe(text, 'drop',    delayedResize);
        observe(text, 'keydown', delayedResize);

        $('.change-registration-info').on('click', delayedResize);
        $('#registration_submit_save').on('click', delayedResize);
        $('#registration-submit').on('click', delayedResize);

        text.focus();
        text.select();
    };
};

Qliq.prototype.resizeTextarea_3 = function() {
    function observe(element, event, handler) {
        element.addEventListener(event, handler, false);
    };

    return function init() {
        var text = document.getElementById('info-accommondation_address');

        function resize () {
            text.style.height = 'auto';
            text.style.height = text.scrollHeight + 'px';
        }

        function delayedResize () {
            window.setTimeout(resize, 0);
        }

        observe(text, 'change',  resize);
        observe(text, 'cut',     delayedResize);
        observe(text, 'paste',   delayedResize);
        observe(text, 'drop',    delayedResize);
        observe(text, 'keydown', delayedResize);

        $('.change-accommodation-info').on('click', delayedResize);
        $('#accommodation_submit_save').on('click', delayedResize);
        $('#accommodation-submit').on('click', delayedResize);


        text.focus();
        text.select();
    };
};

Qliq.prototype.resizeTextarea_4 = function() {
    function observe(element, event, handler) {
        element.addEventListener(event, handler, false);
    };

    return function init() {
        var text = document.getElementById('info-income_company');

        function resize () {
            text.style.height = 'auto';
            text.style.height = text.scrollHeight + 'px';
        }

        function delayedResize () {
            window.setTimeout(resize, 0);
        }

        observe(text, 'change',  resize);
        observe(text, 'cut',     delayedResize);
        observe(text, 'paste',   delayedResize);
        observe(text, 'drop',    delayedResize);
        observe(text, 'keydown', delayedResize);

        $('.change-income-info').on('click', delayedResize);
        $('#income_submit_save').on('click', delayedResize);
        $('#income-submit').on('click', delayedResize);

        text.focus();
        text.select();
    };
};

Qliq.prototype.resizeTextarea_5 = function() {
    function observe(element, event, handler) {
        element.addEventListener(event, handler, false);
    };

    return function init() {
        var text = document.getElementById('info-income_position');

        function resize () {
            text.style.height = 'auto';
            text.style.height = text.scrollHeight + 'px';
        }

        function delayedResize () {
            window.setTimeout(resize, 0);
        }

        observe(text, 'change',  resize);
        observe(text, 'cut',     delayedResize);
        observe(text, 'paste',   delayedResize);
        observe(text, 'drop',    delayedResize);
        observe(text, 'keydown', delayedResize);

        $('.change-income-info').on('click', delayedResize);
        $('#income_submit_save').on('click', delayedResize);
        $('#income-submit').on('click', delayedResize);

        text.focus();
        text.select();
    };
};

Qliq.prototype.resizeTextarea_6 = function() {
    function observe(element, event, handler) {
        element.addEventListener(event, handler, false);
    };

    return function init() {
        var text = document.getElementById('info-income_work-address');

        function resize () {
            text.style.height = 'auto';
            text.style.height = text.scrollHeight + 'px';
        }

        function delayedResize () {
            window.setTimeout(resize, 0);
        }

        observe(text, 'change',  resize);
        observe(text, 'cut',     delayedResize);
        observe(text, 'paste',   delayedResize);
        observe(text, 'drop',    delayedResize);
        observe(text, 'keydown', delayedResize);

        $('.change-income-info').on('click', delayedResize);
        $('#income_submit_save').on('click', delayedResize);
        $('#income-submit').on('click', delayedResize);

        text.focus();
        text.select();
    };
};


Qliq.prototype.clearData = function (value) {

    $('.pers.for-delete__icon').on('click',  function (event) {

        let $input = $(this).prev();

        let vid = $($input).get(0).tagName;


        if(vid == "LABEL") {

            $input = $(this).prev().prev();
            vid = $($input).get(0).tagName;

            if(vid == "INPUT") {
                $($input).val('');
            }

            if(vid == "TEXTAREA") {
                $($input).val('');
            }
        }

        if(vid == "INPUT") {
            $($input).val('');
        }

        if(vid == "TEXTAREA") {
            $($input).val('');
        }

    });

    $('.reg.for-delete__icon').on('click',  function (event) {

        if(vid == "LABEL") {

            $input = $(this).prev().prev();
            vid = $($input).get(0).tagName;

            if(vid == "INPUT") {
                $($input).val('');
            }

            if(vid == "TEXTAREA") {
                $($input).val('');
            }
        }

        if(vid == "INPUT") {
            $($input).val('');
        }

        if(vid == "TEXTAREA") {
            $($input).val('');
        }
    });

    $('.accom.for-delete__icon').on('click',  function (event) {

        let $input = $(this).prev();

        let vid = $($input).get(0).tagName;

        if(vid == "LABEL") {

            $input = $(this).prev().prev();
            vid = $($input).get(0).tagName;

            if(vid == "INPUT") {
                $($input).val('');
            }

            if(vid == "TEXTAREA") {
                $($input).val('');
            }
        }

        if(vid == "INPUT") {
            $($input).val('');
        }

        if(vid == "TEXTAREA") {
            $($input).val('');
        }
    });

    $('.inc.for-delete__icon').on('click',  function (event) {

        let $input = $(this).prev();

        let vid = $($input).get(0).tagName;

        if(vid == "LABEL") {

            $input = $(this).prev().prev();
            vid = $($input).get(0).tagName;

            if(vid == "INPUT") {
                $($input).val('');
            }

            if(vid == "TEXTAREA") {
                $($input).val('');
            }
        }

        if(vid == "INPUT") {
            $($input).val('');
        }

        if(vid == "TEXTAREA") {
            $($input).val('');
        }
    });

    $('.loan.for-delete__icon').on('click',  function (event) {

        let $input = $(this).prev();

        let vid = $($input).get(0).tagName;

        if(vid == "LABEL") {

            $input = $(this).prev().prev();
            vid = $($input).get(0).tagName;

            if(vid == "INPUT") {
                $($input).val('');
            }

            if(vid == "TEXTAREA") {
                $($input).val('');
            }
        }

        if(vid == "INPUT") {
            $($input).val('');
        }

        if(vid == "TEXTAREA") {
            $($input).val('');
        }
    });
};

Qliq.prototype.setTimer = function() {
    let getCurrentTime = 60;
    let currObj = this;

    $('.resend-code').css('color', 'rgba(0, 0, 0, 0.25)');
    $('.resend-code').removeClass('middle-side').removeClass('send-again');

    $('.resend-code').text(`Код отправлен ${getCurrentTime} с`);

    //let timerId
    Qliq.prototype.timerId = setInterval(() => {
        if (getCurrentTime > 0) {
            $('.resend-code').text(`Код отправлен ${getCurrentTime--} с`);
        } else {
            clearInterval(Qliq.prototype.timerId);
            $('.resend-code').css('color', '#00b3a1');
            $('.resend-code').addClass('middle-side').addClass('send-again');
            $('.resend-code').text('Отправить код повторно');

            $('.resend-code').on('click', function() {
                if ($(this).hasClass('send-again')) {
                    currObj.setTimer();
                }
            })
        }
    }, 1000);
};

Qliq.prototype.detectOrientation = function() {
    //if (screen.orientation.angle === 90) {
    if (window.innerHeight < window.innerWidth) {
        $('.container-header').addClass('container-header--landscape');
    } else {
        $('.container-header').addClass('container-header--portrait');
    }

    $(window).on("orientationchange", function() {
        if (window.innerHeight < window.innerWidth) {
            $('body').removeClass('stop-scrolling');

            if (!$('.container-header').hasClass('moving-header')) {
               $('.container-header').removeClass('animatedHeader');
            }

            $('.loan-block__title').removeAttr('style');

            if (!$('.container-header').hasClass('container-header--common')) {
                $('.arrow-js').css('color', '#FFF');
            } else {
                $('.arrow-js').css('color', '#000');
            }

            $('.container-header__header__nav__li').prev().removeClass('active1');
            //Arrow down for mobile portrait menu orientation change
            $('.container-header__header__nav__li').find('.arrow-js').removeClass('open');
            $('.arrow-js .arrow').removeClass('arrow-up').addClass('arrow-down');
            //here is end
            $('.container-header').removeClass('container-header--portrait').addClass('container-header--landscape');
            $('.container-header__header__nav__submenu').removeAttr('style');


        } else {
            $('.loan-block__title').removeAttr('style');
            $('.faq-block__title h1').removeAttr('style');
            $('.about__title h1').removeAttr('style');
            $('.howTo__title h1').removeAttr('style');
            $('.documents__title h1').removeAttr('style');
            $('.conditions__title h1').removeAttr('style');
            $('.bonus__title h1').removeAttr('style');
            $('.container-header__header__nav__submenu').prev().removeClass('open');
            $('.arrow-js .arrow').removeClass('arrow-up').addClass('arrow-down');
            $('.container-header').addClass('container-header--portrait').removeClass('container-header--landscape');
            $('.container-header__header__nav__submenu').removeAttr('style');

            if ($('.container-header__header__logo--mobile').css('display') === 'block') {
                $('body').addClass('stop-scrolling');
            }
        }
    });
};

Qliq.prototype.submitLogin = function() {
    let self = this;

    $('#login-submit').click(function (e) {
        e.preventDefault();

        $('#login-form').submit();
    });

    $('#login-form').submit(function(e) {
        e.preventDefault();

        if (self.validateData($(this))) {
        } else {
        }
    })
}

Qliq.prototype.submitRecovery = function() {
    let self = this;

    $('#recovery-submit').click(function (e) {
        e.preventDefault();

        $('#recovery-form').submit();
    });

    $('#recovery-form').submit(function(e) {
        e.preventDefault();

        if (self.validateData($(this))) {
        } else {
        }
    })
}

Qliq.prototype.setCalendarWidth = function() {
    $('#info-birth, #info-passport_received').on('keyup', function() {
        if($(this).val().length === 10) {
            $('#ui-datepicker-div').hide();
            $('#ui-datepicker-div').outerWidth(document.getElementById('info-birth').getBoundingClientRect().width);
        }
        $('#ui-datepicker-div').outerWidth(document.getElementById('info-birth').getBoundingClientRect().width);
    });
}

Qliq.prototype.setSwipeBlocks = function() {
    if ($(window).width() < 1100) {
        $('.tab-block').on( "swipeleft", function() {
            if (!$('a[data-tab="2"]').hasClass('active-tab')) {
                $('.js-tab').removeClass('active-tab');
                $('a[data-tab="2"]').addClass('active-tab');

                let self = $('a[data-tab="2"]');

                $('.tab-block__element').addClass('hidden-l')
                $('div[data-tab="2"]').removeClass('hidden-r').removeClass('hidden-l');
                $('.tab-menu__line').width($('.active-tab').width());
                let w = $('.line-anchor').width();
                $('.tab-menu__line').css('left', w + 'px');
                $('.line-anchor').removeClass('line-anchor');
                self.parent().addClass('line-anchor');
            }
        });

        $('.tab-block').on( "swiperight", function() {
            if (!$('a[data-tab="1"]').hasClass('active-tab')) {
                $('.js-tab').removeClass('active-tab');
                $('a[data-tab="1"]').addClass('active-tab');

                let self = $('a[data-tab="1"]');

                $('.tab-block__element').addClass('hidden-r');
                $('div[data-tab="1"]').removeClass('hidden-l').removeClass('hidden-r');
                $('.tab-menu__line').width($('.active-tab').width());
                $('.tab-menu__line').css('left', '0');
                $('.line-anchor').removeClass('line-anchor');
                self.parent().addClass('line-anchor');
            }
        });
    }
}

$(document).ready(() => {
    const qliqObj = new Qliq();

    alert("Сайт работает в тестовом режиме. В данный момент идет тестирование, отладка и наполнение сайта");

    qliqObj.getFullPage();
    qliqObj.getRanges();
    qliqObj.setSmoothyFade();
    qliqObj.animateBubble();
    qliqObj.getMobileMenu();
    qliqObj.getSubMenu();
    qliqObj.getMobileOrientation();
    qliqObj.getMobileOperatingSystem();
    qliqObj.tieCard();
    qliqObj.datapicker_init();
    qliqObj.datapicker_init1();
    qliqObj.deleteErrorLabel();
    qliqObj.hoverBtnMenu();
    qliqObj.beginLoan();
    qliqObj.detectOrientation();
    qliqObj.submitLogin();
    qliqObj.submitRecovery();
    qliqObj.getMobileOrientationMenu();
    qliqObj.setCalendarWidth();
    qliqObj.setSwipeBlocks();
    qliqObj.changePlaceTelephone();

    //qliqObj.getHeightThirdTab();
    // qliqObj.bodyClick();

    $('#info-income_employment').chosen({disable_search_threshold: 10});
    $('#info-income_experience').chosen({disable_search_threshold: 10});

    $('#info-accommondation_boolean-phone').on('change', function() {
        if ($('#info-accommondation_boolean-phone').prop('checked')) {
            $('#info-accommondation_income_tel').rules('add', {
                required: true,
                simpleCheckDigitalExtra_2: true
            });
            //$('#info-accommondation_income_tel').valid();
        } else {
            $('#info-accommondation_income_tel').rules('add', {
                required: false,
                simpleCheckDigitalExtra_2: false
            });
            $('#info-accommondation_income_tel').valid();
        }


    });

    if (location.pathname !== '/' && location.pathname !== '/index.html') {
        qliqObj.getOtherMobile_2();
        qliqObj.setMargin();
    }

    if (location.pathname == '/profile.html') {
        qliqObj.resizeTextarea()();
        qliqObj.resizeTextarea_2()();
        qliqObj.resizeTextarea_3()();
        qliqObj.resizeTextarea_4()();
        qliqObj.resizeTextarea_5()();
        qliqObj.resizeTextarea_6()();
    }

    if (location.pathname == '/' || location.pathname == '/index.html' || location.pathname == '/index') {
        qliqObj.getChangeTab();
        qliqObj.getVideo();

        qliqObj.getDeleteAutoHeight();
        qliqObj.setHeightOfTab();
        qliqObj.getMobileOffset();
        qliqObj.getOtherMobile();

        setTimeout(() => {
            $('.tab-menu__line').width($('.tab-menu .active-tab').width());
        }, 500);

    } else {
        qliqObj.getNewHeader();
        qliqObj.getChangeTabLk();
        qliqObj.getOpenCloseProfile();
        qliqObj.showPopup();
        qliqObj.closePopup();
        qliqObj.navigationPopup();
        qliqObj.scrollingRules();
        qliqObj.howToTabs();
        qliqObj.getSubMenuLoan();
        qliqObj.changeInfoLk();
        qliqObj.maskData();
        qliqObj.sendLoanRequest();
        qliqObj.getValidation();
        qliqObj.newPassword();
        qliqObj.changeColorSwitcher();
        qliqObj.changeAgreement();
        qliqObj.clearData();
        qliqObj.hoverBtn();
        //qliqObj.swipeElements();
    }
    qliqObj.map_init();

});
